import {
  fetchApplication,
  TactileCompany,
  TactileCompanyStoreSubmission,
} from '@introcloud/api-client';
import { DEFAULT_TABS } from '@introcloud/tabs';
import Constants from 'expo-constants';
import { FetchMediaError } from 'fetch-media';
import merge from 'lodash.merge';
import { generateUUID } from 'pubnub';
import { useMemo } from 'react';
import { DarkTheme, DefaultTheme } from 'react-native-paper';
import { useQuery, UseQueryResult } from 'react-query';

import {
  StoredMemoryValue,
  useMemoryValue,
  useMutableMemoryValue,
} from '../storage';
import { useAbortController } from './useAbortController';

export const COMPANY = new StoredMemoryValue<TactileCompany>(
  'application.company.v1'
);

type Theme = typeof DarkTheme | typeof DefaultTheme;

declare type Mutable<T extends Record<string, unknown>> = {
  -readonly [K in keyof T]: T[K] extends Record<string, any>
    ? Mutable<T[K]>
    : T[K];
};

export type LocalCompany = Mutable<
  Omit<TactileCompany, 'application'> & {
    application: Omit<TactileCompany['application'], 'themes'> & {
      themes: Omit<
        TactileCompany['application']['themes'],
        'light' | 'dark'
      > & {
        light: Theme;
        dark: Theme;
      };
    };
  }
>;

const DEFAULT_COMPANY: LocalCompany = {
  application: {
    splash: {
      backgroundColor: Constants.manifest.splash.backgroundColor,
      resizeMode: Constants.manifest.splash.resizeMode,
      imageId: null,
      image: null,
    },
    map: {
      center: {
        latitude: 0,
        longitude: 0,
      },
    },
    store: {} as TactileCompanyStoreSubmission,
    themes: {
      default: 'light',
      allowSwitching: false,
      light: {
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          primary:
            Constants.manifest.primaryColor || DefaultTheme.colors.primary,
        },
      },
      dark: {
        ...DarkTheme,
        colors: {
          ...DarkTheme.colors,
          primary: Constants.manifest.primaryColor || DarkTheme.colors.primary,
        },
      },
    },
    standalone: {
      ios: { icon: null },
      android: { icon: null },
      adaptive: {
        foregroundImage: null,
        backgroundColor:
          Constants.manifest.android?.adaptive?.backgroundColor || null,
      },
      notification: {
        icon: null,
      },
    },
    advertisements: {
      defaultRatio: {
        x: '16',
        y: '9',
      },
    },
    events: {
      type: 'description-focus',
      tagsEnabled: true,
      imageEnabled: true,
      lines: 3,
    },
    tabs: {
      neutral: true,
      fallback: null,
      configuration: {
        information: {
          destination: {
            kind: 'info',
            value: null,
          },
        },
        'event-days': {
          duration: {
            start: {
              unix: 0,
            },
            end: {
              unix: 0,
            },
          },
          images: {},
        },
        custom: {
          destination: {
            kind: 'external',
            value: null,
          },
          passAuth: false,
          immersive: false,
        },
      },
      values: DEFAULT_TABS.map((tab) => ({ ...tab, _id: generateUUID() })),
    },
    settings: {
      application: {
        userCanAccess: null,
      },
      pubnub: {
        active: null,
      },
    },
  },
  name: {
    full: Constants.manifest.name || '',
    abbr: Constants.manifest.name || '',
  },
  domain: Constants.manifest.extra.endpoint
    .split('://')
    .pop()
    .split('/api')
    .shift(),
  image: {
    banner: null,
    profile: null,
  },
};

export function useCompany(): LocalCompany {
  const remoteCompany = (useMemoryValue(COMPANY) as unknown) as TactileCompany;

  return useMemo(() => {
    const merged: LocalCompany = merge<LocalCompany, Partial<TactileCompany>>(
      DEFAULT_COMPANY,
      remoteCompany || ({} as Partial<TactileCompany>)
    );

    if (remoteCompany?.application?.tabs?.values?.length || 0 > 2) {
      merged.application.tabs.values = remoteCompany!.application.tabs.values;
    }

    return merged;
  }, [remoteCompany]);
}

export function useRemoteCompany(
  domainFull: string | undefined
): UseQueryResult<TactileCompany, FetchMediaError | Error> {
  const abortable = useAbortController();

  return useQuery(
    ['api', 'company', domainFull],
    () => {
      if (!domainFull) {
        throw new Error('Need a domain to grab the app');
      }

      if (domainFull === 'https://app.tactile.events') {
        throw new Error("Can't grab the generic app.tactile.events.");
      }

      const ac = abortable();

      const cancellable = fetchApplication(
        [domainFull, 'api'].join('/'),
        ac.signal,
        __DEV__
      );

      // This is a non-standard property on a promise, so the error here needs to
      // be ignored. However, react-query will check this non-standard property
      // and use it if it's available.
      //
      // @ts-ignore
      cancellable.cancel = () => {
        ac && ac.abort();
      };

      return cancellable;
    },
    { enabled: !!domainFull }
  );
}

// Only update company when the app restarts
export function useMutableCompany() {
  return useMutableMemoryValue(COMPANY);
}
