import { useBlockNavigation } from '@introcloud/blocks';
import Color from 'color';
import React, { Fragment, useMemo } from 'react';
import { FlatList, ListRenderItemInfo, Platform, View } from 'react-native';
import { Avatar, List, Surface, Text, useTheme } from 'react-native-paper';
import { useQuery } from 'react-query';
import { BlockProvision } from '../core/BlockProvision';
import { EmptyState } from '../core/EmptyState';
import { Header } from '../core/Header';
import {
  AnyMessage,
  ChatMembership,
  ChatMessage,
  useStoredChats,
} from '../hooks/useChats';
import { useCompanyTabs } from '../hooks/useCompanyTabs';
import { useForceUpdate } from '../hooks/useForceUpdate';
import { useNameFetcher } from '../hooks/useNameFetcher';
import { SHOULD_ALLOW_DEBUG } from '../utils';
import { useChannelMessageListener } from './ProvideInAppChats';
import { StoredChatMessage, useLastMessage } from './useChatHistory';
import { useChatName } from './useChatName';
import { useChatOneOnOneUser } from './useChatOneOnOneUser';

const EMPTY_TEXT = {
  en: `
  There are various opportunities to talk to others using the chat
  functionality. Chats with your group, organizations, or associations. They'll
  show up here when you've participated once.
  `
    .trim()
    .replace(/\n/g, ''),
  nl: `
  Er zijn verschillende kansen om met anderen te praten via de chat
  functionaliteit. Chats met je groepje, organisaties, of verenigingen en andere
  instanties. Je vindt ze hier zodra je hebt deelgenomen aan een gesprek.
  `
    .trim()
    .replace(/\n/g, ''),
};

export function ChatsScreen({ asTab }: { asTab?: boolean }) {
  const { values } = useCompanyTabs();
  const tab = useMemo(() => values.find((tab) => tab.tab === 'chats'), [
    values,
  ]);
  const icon = useMemo(() => (tab ? tab.icon.name : 'forum'), [tab]);
  const title = useMemo(() => tab?.title, [tab]);

  const chats = useStoredChats();
  const hasChats = !!(
    chats &&
    (chats.length > 1 || (chats.length > 0 && chats[0].custom.type !== 'self'))
  );

  return (
    <BlockProvision screen="ChatsScreen">
      <View
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          maxHeight: Platform.select({ web: '100vh', default: '100%' }),
        }}
      >
        <Header
          title={title || 'Chats'}
          subTitle={undefined}
          hideBack={asTab}
          style={{ elevation: hasChats ? undefined : 2 }}
        />
        {hasChats ? <ChatsList chats={chats!} /> : null}
        <EmptyState
          hidden={hasChats}
          icon={icon}
          title={title || 'Chats'}
          texts={EMPTY_TEXT}
        />
      </View>
    </BlockProvision>
  );
}

function ChatsList({ chats }: { chats: readonly ChatMembership[] }) {
  return (
    <FlatList
      nativeID="scroller"
      style={{ flex: 1 }}
      contentContainerStyle={{
        maxWidth: 720,
        alignSelf: 'center',
        width: '100%',
      }}
      data={chats}
      renderItem={renderItem}
      keyExtractor={extractKey}
      initialNumToRender={15}
    />
  );
}

function extractKey(item: ChatMembership) {
  return item.id;
}

type Item = ChatMembership;

function renderItem(info: ListRenderItemInfo<Item>) {
  // No custom data, so don't know how to deal with it
  if (!info.item.custom) {
    return null;
  }

  return (
    <ChatRoom
      item={info.item}
      index={info.index}
      separators={info.separators}
      key={info.item.id}
    />
  );
}

function ChatRoom_({ item }: ListRenderItemInfo<ChatMembership>) {
  const forceUpdate = useForceUpdate();

  useChannelMessageListener(item.id, forceUpdate);

  const ListComponent = listComponent(item.custom.type);

  return (
    <Surface style={{ elevation: 1, width: '100%' }}>
      <ListComponent item={item} />
    </Surface>
  );
}

function listComponent(kind: ChatMembership['custom']['type']) {
  switch (kind) {
    case 'page-1-on-1':
    case 'page':
      return PageChatRoom;
    case 'page-group':
      return PageGroupChatRoom;
    case 'group':
      return GroupChatRoom;
    case '1-on-1':
      return UserChatRoom;
    case 'self':
      return SelfChatRoom;
    default:
      return () => null;
  }
}

function PageChatRoom({ item }: { item: ChatMembership }) {
  const lastMessage = useLastMessage(item.id);
  const page = item.custom.refs.find((ref) => ref.model === 'page');

  const nameFetcher = useNameFetcher('page');
  const { data: name } = useQuery(
    ['chat', 'page', page?.id, 'name'],
    () => nameFetcher(page!.id),
    {
      enabled: !!page?.id,
      staleTime: 15 * 60 * 1000,
    }
  );

  return (
    <GenericChatRoomListItem
      channelId={item.id}
      channelName={name}
      channelKind={item.custom.type}
      lastMessage={lastMessage}
      renderIcon={(props) => <List.Icon {...props} icon="forum" />}
    />
  );
}

function GroupChatRoom({ item }: { item: ChatMembership }) {
  const group = item.custom.refs.find((ref) => ref.model === 'group');

  const lastMessage = useLastMessage(item.id);
  const groupName = useChatName(group, 'group');

  return (
    <GenericChatRoomListItem
      renderIcon={(props) => <List.Icon {...props} icon="account-group" />}
      channelId={item.id}
      channelName={groupName}
      channelKind={item.custom.type}
      lastMessage={lastMessage}
    />
  );
}

function PageGroupChatRoom({ item }: { item: ChatMembership }) {
  const page = item.custom.refs.find((ref) => ref.model === 'page');
  const group = item.custom.refs.find((ref) => ref.model === 'group');

  const lastMessage = useLastMessage(item.id);
  const groupName = useChatName(group, 'group');
  const pageName = useChatName(page, 'page');

  return (
    <GenericChatRoomListItem
      renderIcon={(props) => <List.Icon {...props} icon="forum" />}
      channelId={item.id}
      channelName={`${pageName || '-'} (${groupName || '-'})`}
      channelKind={item.custom.type}
      lastMessage={lastMessage}
    />
  );
}

function UserChatRoom({ item }: { item: ChatMembership }) {
  const lastMessage = useLastMessage(item.id);
  const { image, name, initials } = useChatOneOnOneUser(item, 'icon_64');
  const {
    colors: { primary },
  } = useTheme();

  return (
    <GenericChatRoomListItem
      renderIcon={(props) => <ChatAvatar image={image} initials={initials} />}
      channelName={name}
      channelId={item.id}
      channelKind={item.custom.type}
      lastMessage={lastMessage}
    />
  );
}

function ChatAvatar({
  image,
  initials,
}: {
  image: string | null;
  initials: string;
}) {
  const {
    colors: { primary, background },
  } = useTheme();

  if (image) {
    return (
      <Avatar.Image
        source={{ uri: image, width: 64, height: 64 }}
        size={40}
        style={{
          marginLeft: 4,
          marginRight: 12,
          marginTop: 8,
          marginBottom: 8,
          backgroundColor: background,
        }}
      />
    );
  }

  const color = new Color(primary);

  return (
    <Avatar.Text
      label={initials}
      size={40}
      style={{ marginLeft: 4, marginRight: 12, marginTop: 8, marginBottom: 8 }}
      color={color.isDark() ? '#fff' : '#000'}
    />
  );
}

function SelfChatRoom({ item }: { item: ChatMembership }) {
  const lastMessage = useLastMessage(item.id);

  if (!SHOULD_ALLOW_DEBUG) {
    return null;
  }

  return (
    <GenericChatRoomListItem
      renderIcon={(props) => <List.Icon {...props} icon="message-cog" />}
      channelName="System"
      channelId={item.id}
      channelKind={item.custom.type}
      lastMessage={lastMessage}
      disabled
    />
  );
}

function GenericChatRoomListItem({
  channelId,
  channelKind,
  channelName,
  lastMessage,
  renderIcon,
  disabled,
}: {
  channelId: string;
  channelKind: string;
  channelName?: string;
  lastMessage?: ReturnType<typeof useLastMessage>;
  renderIcon: React.ComponentProps<typeof List.Item>['left'];
  disabled?: boolean;
}) {
  const { gotoChat } = useBlockNavigation();

  return (
    <List.Item
      left={renderIcon}
      title={channelName || '-'}
      titleStyle={{ fontWeight: lastMessage?.unread ? 'bold' : 'normal' }}
      descriptionNumberOfLines={3}
      disabled={disabled}
      description={(props) => (
        <LastMessage
          {...props}
          message={lastMessage?.message}
          kind={channelKind}
        />
      )}
      right={
        disabled
          ? undefined
          : (props) => (
              <List.Icon
                icon="chevron-right"
                {...props}
                style={{ ...props.style, marginHorizontal: 8 }}
              />
            )
      }
      onPress={() => {
        gotoChat(channelId);
      }}
    />
  );
}

function LastMessage({
  message,
  kind,
  color,
  fontSize,
  ellipsizeMode,
}: {
  message: StoredChatMessage | undefined;
  kind: string;
  color: string;
  fontSize: number;
  ellipsizeMode: any;
}) {
  const name = useChatName(
    message ? { id: message.publisher } : undefined,
    'user'
  );

  const content = assertChatMessage(message?.message)
    ? message?.message.c || normalize(kind)
    : normalize(kind);

  return (
    <Text
      numberOfLines={3}
      style={{ color, fontSize, marginTop: 4 }}
      ellipsizeMode={ellipsizeMode}
    >
      {content ? (
        <Fragment>
          {name ? (
            <Text
              style={{ fontSize, fontWeight: 'bold', color, marginRight: 4 }}
            >
              {name}:
            </Text>
          ) : null}
          {content}
        </Fragment>
      ) : (
        normalize(kind)
      )}
    </Text>
  );
}

function assertChatMessage(
  message: AnyMessage | undefined
): message is ChatMessage {
  if (!message) {
    return false;
  }

  return message.t[0] === 'c';
}

function normalize(kind: string) {
  switch (kind) {
    case 'page-1-on-1':
      return 'Page chat (private)';
    case 'page':
      return 'Page chat (everyone)';
    case 'page-group':
      return 'Page chat (group)';
    case 'group':
      return 'Group chat';
    case '1-on-1':
      return 'Private chat';
    default:
      return 'Chat';
  }
}

const ChatRoom = React.memo(ChatRoom_);
