import color from 'color';
import * as SplashScreen from 'expo-splash-screen';
import React, { useState } from 'react';
import { Platform, StatusBar, StyleSheet, View } from 'react-native';
import { Provider } from 'react-native-paper';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { AuthenticatedApp } from './core/AuthenticatedApp';
import { Connectivity } from './core/Connectivity';
import { OtaUpdater } from './core/OtaUpdater';
import { QueryCacheProvider, queryClient } from './core/QueryCache';
import {
  AuthenticationProvider,
  runOnLogout,
  useSafeAuthorization,
} from './hooks/useAuthentication';
import {
  useCachedResources,
  useSplashResources,
} from './hooks/useCachedResources';
import { useCompany } from './hooks/useCompany';
import { LoginScreen } from './login';
import { SplashOrganisation } from './splash/Splasher';

runOnLogout(() => {
  queryClient.clear();
});

let hiddenSplash = false;

export function Root() {
  const [isBlockingSplashPointerEvents, setBlockSplashPointerEvents] = useState(
    false
  );
  const [isSplashReady, setSplashReady] = useState(true);
  const [isSplashDone, setSplashDone] = useState(false);

  const canSplashShow = useSplashResources();
  const isLoadingComplete = useCachedResources();

  return (
    <AuthenticationProvider>
      <View style={{ position: 'relative', flex: 1 }}>
        {isLoadingComplete && isSplashReady ? <App /> : null}
        {!isSplashDone && canSplashShow && (
          <View
            pointerEvents={isBlockingSplashPointerEvents ? 'none' : 'auto'}
            style={StyleSheet.absoluteFillObject}
          >
            <SplashOrganisation
              onReady={() => setSplashReady(true)}
              onDone={() => setSplashDone(true)}
              onAlmostDone={() => setBlockSplashPointerEvents(true)}
              onLoaded={() => {
                if (hiddenSplash) {
                  return;
                }

                SplashScreen.hideAsync().then(
                  () => (hiddenSplash = true),
                  () => (hiddenSplash = true)
                );
              }}
            />
          </View>
        )}
      </View>
    </AuthenticationProvider>
  );
}

function App() {
  return (
    <QueryCacheProvider>
      <SafeAreaProvider>
        <PreloadedApp />
      </SafeAreaProvider>
    </QueryCacheProvider>
  );
}

function PreloadedApp() {
  const company = useCompany();
  const authorization = useSafeAuthorization();

  const { dark, light, default: chosen } = company.application.themes;

  // TODO switching logic
  const theme = chosen === 'dark' ? dark : light;
  const primaryIsDark = color(theme.colors.primary).isDark();
  return (
    <SafeAreaProvider>
      <Provider
        theme={theme}
        key={authorization ? 'authorized' : 'not-authorized'}
      >
        <StatusBar
          barStyle={
            primaryIsDark || (theme.dark && theme.mode !== 'exact')
              ? 'light-content'
              : 'dark-content'
          }
          translucent
          backgroundColor="#00000000"
        />
        {authorization ? <AuthenticatedApp /> : <LoginScreen />}
        <Connectivity />
        {Platform.OS !== 'web' ? <OtaUpdater /> : null}
      </Provider>
    </SafeAreaProvider>
  );
}
