import React, { useMemo } from 'react';
import { View } from 'react-native';

import { BlockProvision } from '../core/BlockProvision';
import { EmptyState } from '../core/EmptyState';
import { Header } from '../core/Header';
import { useCompanyTabs } from '../hooks/useCompanyTabs';
import { useSwipeInterview } from '../hooks/useSwipeInterview';
import { SwipeEvent } from './SwipeEvent';

const EMPTY_TEXT = {
  en: `
  During various moments you can match with others with similar interests, beyond your
  own group. Check back later!
  `
    .trim()
    .replace(/\n/g, ''),
  nl: `
  Op verschillende momenten kan je hier anderen ontmoeten met soortgelijke interesses, ook al
  zitten ze niet in je eigen groepje. Je bent dan welkom!
  `
    .trim()
    .replace(/\n/g, ''),
};

export function MatchingScreen({ asTab }: { asTab?: boolean }) {
  const { values } = useCompanyTabs();
  const tab = useMemo(() => values.find((tab) => tab.tab === 'matching'), [
    values,
  ]);
  const icon = useMemo(() => (tab ? tab.icon.name : 'gesture-swipe'), [tab]);
  const title = useMemo(() => tab?.title, [tab]);

  const event = useSwipeInterview();
  const isReady =
    event &&
    event.module.interview.question.length > 0 &&
    event.module.interview.question[0].type === 'textarea';

  return (
    <BlockProvision screen="MatchingTab">
      <View style={{ position: 'relative', width: '100%', height: '100%' }}>
        <Header
          title={title || 'Matching'}
          subTitle={undefined}
          hideBack={asTab}
          style={{ elevation: event ? undefined : 2 }}
        />
        {isReady ? <SwipeEvent {...event!} /> : null}
        <EmptyState
          hidden={!!isReady}
          icon={icon}
          title={title || 'Matching'}
          texts={EMPTY_TEXT}
        />
      </View>
    </BlockProvision>
  );
}
