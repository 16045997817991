import { TactilePage } from '@introcloud/api-client';
import { FetchMediaError } from 'fetch-media';
import { useCallback } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { PAGE_CACHE } from '../core/Cache';
import { useIsAuthenticated } from './useAuthentication';

export function usePage(
  pageId: string | null | undefined,
  getInfoById: (pageId: string) => Promise<TactilePage>,
  {
    enabled = true,
    ...options
  }: UseQueryOptions<TactilePage | null, FetchMediaError | Error> = {}
) {
  const isAuthenticated = useIsAuthenticated();
  const initialPage = pageId ? PAGE_CACHE.get(pageId) : null;

  const fetcher = useCallback(() => getInfoById(pageId!), [
    getInfoById,
    pageId,
  ]);

  const { data: page, error, ...others } = useQuery(
    ['application', 'page', pageId],
    fetcher,
    {
      placeholderData: initialPage?.content
        ? (initialPage as TactilePage)
        : undefined,
      enabled: enabled && !!pageId && isAuthenticated,
      staleTime: 30 * 1000,
      ...options,
    }
  );

  return {
    page,
    revalidate: others.refetch,
    error,
    loading: others.isLoading,
    ...others,
  };
}
