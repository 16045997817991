import Constants from 'expo-constants';
import * as Sentry from 'sentry-expo/dist/sentry.web';

const SENTRY_DSN = Constants.manifest.extra['sentry-dsn'];

Sentry.init({
  debug: __DEV__,
  dsn: SENTRY_DSN,
  enableInExpoDevelopment: false,
});

const Browser = Sentry.Browser;
export { Browser as Sentry };
