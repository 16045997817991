import { TactileCompanyTabs } from '@introcloud/api-client/dist/fetch/types';
import { RemoteTabsConfiguration } from '@introcloud/tabs';
import { useMemo } from 'react';

import { TAB_COLORS } from '../features';
import { useCompany } from './useCompany';

export function useCompanyTabs() {
  const tabs = useCompany().application.tabs;
  /*
  tabs.values[1] = {
    _id: 'test-third-info',
    tab: 'information3',
    title: 'Real Info',
    icon: {
      family: 'MaterialCommunityIcons',
      name: 'alert',
    },
  };

  tabs.values[3] = {
    _id: 'game-map',
    tab: 'game-map',
    title: 'Game Map',
    icon: {
      family: 'MaterialCommunityIcons',
      name: 'animation',
    },
  };

  tabs.configuration['information3'] = {
    destination: {
      kind: 'info',
      value: '5ffaf07440f1e3a53afdbf5b',
    },
  };

  tabs.configuration['game-map'] = {
    mapRef: {
      mapId: '60016cfa9d2b73283ffc7eda',
    },
  };
*/
  return useMemo(() => validate(tabs), [tabs]);
}

function validate(
  tabs: TactileCompanyTabs
): Omit<TactileCompanyTabs, 'values'> & { values: RemoteTabsConfiguration } {
  // TODO validate tabs
  return {
    ...tabs,
    values: colorize(tabs.values as RemoteTabsConfiguration).filter(
      (tab, index, self) =>
        self.findIndex((item) => item.tab === tab.tab) === index
    ),
  };
}

function colorize(tabs: RemoteTabsConfiguration): RemoteTabsConfiguration {
  const colors = TAB_COLORS;
  if (!colors || colors.length === 0) {
    return tabs;
  }

  return tabs.map((tab, index) => {
    return {
      ...tab,
      color: colors[index],
    };
  });
}
