import { useState, useCallback } from 'react';

export function useForceUpdate() {
  const [, setCount] = useState(0);

  return useCallback(() => setCount((prev) => prev + 1), [setCount]);
}

export function useForceUpdateCount(): [number, () => void] {
  const [count, setCount] = useState(0);
  const forceUpdate = useCallback(() => setCount((prev) => prev + 1), [
    setCount,
  ]);
  return [count, forceUpdate];
}
