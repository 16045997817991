import { TactileContentBlock } from '@introcloud/api-client';
import { PageData } from '@introcloud/page';
import {
  KIND_HOME_COUNTDOWN,
  KIND_HOME_EVENTS_RELEVANT,
  KIND_HOME_NEWS,
  KIND_HOME_PUSH_NOTIFICATIONS,
  KIND_HOME_QUICK_CONTACTS,
  KIND_HOME_STORE_LINKS,
  KIND_SPACER,
} from '@introcloud/page/dist/Kinds';
import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useMemo } from 'react';
import { Appbar } from 'react-native-paper';
import { v4 as uuid } from 'uuid';
import { BlockProvision } from '../core/BlockProvision';
import { CHAT_BUTTONS_ENABLED } from '../features';
import { useTab } from '../hooks/useTab';
import { useTabColor } from '../hooks/useTabColor';
import { useUser } from '../hooks/useUser';
import { PageViewer } from '../page/PageViewer';

export function HomeScreen({ asTab }: { asTab?: boolean }) {
  const { data: currentUser } = useUser();

  const tab = useTab('home');
  const color = useTabColor('home');
  const page = useMemo(
    (): PageData => ({
      page: {
        _id: uuid(),
        _v: 0,
        description: '',
        eventRef: [],
        locationRef: [],
        name: {
          id: 'home',
          full: tab?.title || 'Home',
        },
        module: {
          application: {
            colors: {
              primary: '',
              accent: '',
            },
          },
        },
        content: DEFAULT_HOME_CONTENT,
      },
      pageEvent: null,
      pageLocation: null,
      currentUser: currentUser || null,
      currentGroup: null,
    }),
    []
  );

  return (
    <BlockProvision screen="HomeScreen" page={page}>
      <PageViewer hideBack={asTab} colorOverride={color}>
        {CHAT_BUTTONS_ENABLED ? <GotoChatsAction /> : null}
      </PageViewer>
    </BlockProvision>
  );
}

function GotoChatsAction() {
  const tab = useTab('chats');
  const { navigate } = useNavigation();

  const onGotoChats = useCallback(() => {
    navigate('Chats', undefined);
  }, [navigate]);

  return (
    <Appbar.Action
      icon={
        ((!tab?.icon.family || tab?.icon.family === 'MaterialCommunityIcons') &&
          (tab?.icon.name as 'forum')) ||
        'chat'
      }
      onPress={onGotoChats}
    />
  );
}

const DEFAULT_HOME_CONTENT: TactileContentBlock[] = [
  /*{
    _id: uuid(),
    kind: KIND_SPONSOR,
    value: {
      src: HOME_PAGE_BANNER
    },
    options: {}
  },*/
  {
    _id: uuid(),
    kind: KIND_HOME_COUNTDOWN,
    value: {},
    options: {},
  },
  {
    _id: uuid(),
    kind: KIND_SPACER,
    options: {
      space: 1,
    },
    value: {},
  },
  {
    _id: uuid(),
    kind: KIND_HOME_EVENTS_RELEVANT,
    options: {},
    value: {},
  },
  {
    _id: uuid(),
    kind: KIND_HOME_PUSH_NOTIFICATIONS,
    options: {},
    value: {},
  },
  {
    _id: uuid(),
    kind: KIND_HOME_NEWS,
    options: {},
    value: {},
  },
  {
    _id: uuid(),
    kind: KIND_HOME_STORE_LINKS,
    options: {},
    value: {},
  },
  {
    _id: uuid(),
    kind: KIND_HOME_QUICK_CONTACTS,
    options: {},
    value: {},
  },
];
