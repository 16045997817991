import { TactileImageSize } from '@introcloud/blocks';
import { useMemo } from 'react';
import { ChatMembership } from '../hooks/useChats';
import { useUser } from '../hooks/useUser';
import { useChatImage } from './useChatImage';
import { useChatInitials } from './useChatInitials';
import { useChatUserInfo } from './useChatUserInfo';

export function useChatOneOnOneUser(
  item: ChatMembership,
  size: TactileImageSize = 'icon_32'
) {
  const { data: self } = useUser();

  const chatUser = useMemo(
    () =>
      item.custom.refs.find(
        (ref) => ref.id !== self?._id && ref.model === 'user'
      ),
    [self?._id, item]
  );

  const chatUserInfo = useChatUserInfo(chatUser);
  const initials = useChatInitials(chatUserInfo);
  const image = useChatImage(chatUserInfo, size);

  return {
    name: chatUserInfo?.name?.full,
    initials,
    image,
  };
}
