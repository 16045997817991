import React from 'react';
import { Image } from 'react-native';
import { BlockOptions, BlockProps } from '@introcloud/page';
import { Caption, Card, Paragraph, Title } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { RouteNavigation } from '../core/Routes';

export function GelegraafBlock(
  block: BlockProps<BlockOptions> & { kind: 'custom' }
) {
  const { navigate } = useNavigation<RouteNavigation<'Info'>>();
  return (
    <Card
      onPress={() => navigate('Gelegraaf')}
      style={{
        alignItems: 'center',
        paddingBottom: 16,
        marginTop: 16,
        marginHorizontal: 16,
      }}
    >
      <Image
        style={{ width: 150, height: 150, alignSelf: 'center', margin: 'auto' }}
        source={require('../../assets/news.png')}
      />
      <Title
        key="description"
        style={{
          textAlign: 'center',
          alignSelf: 'center',
          paddingHorizontal: 16,
          paddingVertical: 0,
          lineHeight: 24,
        }}
      >
        Digital edition of the Gelegraaf
      </Title>
      <Paragraph
        key="check-back"
        style={{
          textAlign: 'center',
          alignSelf: 'center',
          marginHorizontal: 16,
          marginTop: 8,
          marginBottom: 0,
        }}
      >
        During intreeweek we publish articles and photos in this digital edition
        of the Gelegraaf, our daily digital magazine.{' '}
        <Caption>
          You can read the Gelegraaf every day from 09:00 by clicking this card.
        </Caption>
      </Paragraph>
    </Card>
  );
}
