import { PrimaryButton, Spacer } from '@introcloud/blocks';
import Constants from 'expo-constants';
import { makeUrl } from 'expo-linking';
import {
  askAsync,
  getAsync,
  NOTIFICATIONS,
  PermissionStatus,
} from 'expo-permissions';
import * as ExpoNotifications from 'expo-notifications';
import { fetchMedia } from 'fetch-media';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Clipboard, Platform } from 'react-native';
import { Avatar, Button, Card, Paragraph, useTheme } from 'react-native-paper';
import { useMediaQuery } from 'react-responsive';
import { useIsMounted } from 'use-is-mounted';
import { useEndpoint, useSafeAuthorization } from '../hooks/useAuthentication';
import { useUser } from '../hooks/useUser';
import { SHOULD_ALLOW_DEBUG } from '../utils';

const IS_SUPPORTED = Platform.select({
  web: false,
  default: true,
});

const EXPO_SLUG = Constants.manifest.slug || 'unknown';

const lastIsPermitted: { value: PermissionStatus | undefined } = {
  value: PermissionStatus.UNDETERMINED,
};

export function Notifications(): null | JSX.Element {
  const { data: currentUser } = useUser();
  const authorization = useSafeAuthorization();
  const endpoint = useEndpoint();
  const { roundness } = useTheme();
  const mobileView = useMediaQuery({ query: '(max-width: 736px)' }); // 720 + 16

  const [hasChecked, setHasChecked] = useState(false);
  const [isPermitted, setPermitted] = useState<PermissionStatus | undefined>(
    lastIsPermitted.value
  );
  const [currentPushToken, setCurrentPushToken] = useState<string | undefined>(
    undefined
  );
  const isMounted = useIsMounted();

  const doEnableNotifications = useCallback(() => {
    askAsync(NOTIFICATIONS)
      .then(({ status }) => {
        lastIsPermitted.value = status;
        if (isMounted.current) {
          setPermitted(status);
          setHasChecked(false);
        }
      })
      .catch((err) => {
        if (isMounted.current) {
          setHasChecked(false);
        }

        console.error(err);
      });
  }, [isMounted]);

  /*const renderBannerImage = useCallback(({ size }) => {
    return <Image style={{ width: size, height: size }} source={require('../../assets/notification.png')} />
  }, [])*/

  // Effect to get the token
  useEffect(() => {
    if (isPermitted === PermissionStatus.UNDETERMINED) {
      getAsync(NOTIFICATIONS)
        .then(({ status }) => {
          lastIsPermitted.value = status;
          if (isMounted.current) {
            setPermitted(status);
            setHasChecked(true);
          }
        })
        .catch((err) => {
          if (isMounted.current) {
            setHasChecked(true);
          }

          console.error(err);
        });
      return;
    }

    if (
      isPermitted === PermissionStatus.GRANTED &&
      !currentPushToken &&
      IS_SUPPORTED
    ) {
      ExpoNotifications.getExpoPushTokenAsync()
        .then((result) => isMounted.current && setCurrentPushToken(result.data))
        .catch(console.warn);
    }

    return () => {};
  }, [isMounted, isPermitted, setCurrentPushToken, currentPushToken]);

  // Effect to store the token
  useEffect(() => {
    if (!endpoint) {
      return;
    }

    // TODO remove when anonymous is ok
    if (!authorization) {
      return;
    }

    if (isPermitted === PermissionStatus.GRANTED && currentPushToken) {
      const urlData = currentUser?._id
        ? `${encodeURIComponent(
            currentPushToken
          )}/expo-slug/${EXPO_SLUG}/${encodeURIComponent(currentUser._id)}`
        : `${encodeURIComponent(currentPushToken)}/expo-slug/${EXPO_SLUG}`;

      fetchMedia(`${endpoint}/public/push-token/${urlData}`, {
        headers: { authorization, accept: 'application/json' },
      }).catch((error) => __DEV__ && console.error(error));
    }
  }, [endpoint, authorization, currentPushToken, currentUser, isPermitted]);

  // This means that we don't know yet
  if (isPermitted === undefined || hasChecked === false) {
    if (!SHOULD_ALLOW_DEBUG) {
      return null;
    }
  }

  if (isPermitted !== PermissionStatus.GRANTED && IS_SUPPORTED) {
    return (
      <Fragment>
        <Card
          onPress={doEnableNotifications}
          style={{
            borderRadius: mobileView ? 0 : roundness,
          }}
        >
          <Card.Title
            title="Get important updates"
            subtitle="Enable push notifications"
            left={(props) => (
              <Avatar.Image
                {...props}
                size={48}
                style={{ padding: 0, backgroundColor: 'transparent' }}
                source={require('../../assets/notification.png')}
              />
            )}
          />
          <Card.Content>
            <Paragraph>
              Allow push notifications to get updates about last-minute changes
              to the programme, important personal information, or reminders for
              important events you're participating in.
            </Paragraph>
          </Card.Content>

          <Card.Actions>
            <PrimaryButton
              onPress={doEnableNotifications}
              style={{ marginLeft: 'auto' }}
            >
              Enable
            </PrimaryButton>
          </Card.Actions>
        </Card>
        <Spacer space={1} />
      </Fragment>
    );
  }

  if (!SHOULD_ALLOW_DEBUG) {
    return null;
  }

  if (!IS_SUPPORTED || !currentPushToken) {
    return null;
  }

  return (
    <Fragment>
      <Card
        style={{
          borderRadius: mobileView ? 0 : roundness,
        }}
      >
        <Card.Title
          title="Push notifications enabled"
          subtitle="...and you're in DEV mode"
          left={(props) => (
            <Avatar.Image
              {...props}
              size={48}
              style={{ padding: 0, backgroundColor: 'transparent' }}
              source={require('../../assets/notification.png')}
            />
          )}
        />
        <Card.Content>
          <Paragraph>
            Allow push notifications to get updates about last-minute changes to
            the programme, important personal information, or reminders for
            important events you're participating in.
          </Paragraph>
        </Card.Content>

        <Card.Actions>
          <Button
            icon="bell"
            onPress={() => {
              ExpoNotifications.scheduleNotificationAsync({
                content: {
                  title: 'Test notification',
                  body: 'This is an example of a local notification.',
                  data: {
                    title: 'In-app test notification',
                    body: 'This is an example of a local notification.',
                  },
                },
                trigger: { seconds: 2 },
              });
            }}
            style={{ flex: 1, marginHorizontal: 4 }}
            mode="contained"
          >
            Local
          </Button>

          <Button
            icon="bell-ring"
            onPress={() => {
              ExpoNotifications.scheduleNotificationAsync({
                content: {
                  title: 'Test notification',
                  body: 'This is an example of a local notification.',
                  data: {
                    title: 'In-app test notification',
                    body: 'This is an example of a local notification.',
                    action: 'Show info',
                    url: makeUrl('/knowledge-base'),
                  },
                },
                trigger: null,
              });
            }}
            style={{ flex: 1, marginHorizontal: 4 }}
            mode="contained"
          >
            Present
          </Button>

          <PrimaryButton
            icon="content-copy"
            onPress={() => {
              Clipboard.setString(currentPushToken);

              console.log(currentPushToken);
            }}
            style={{ marginLeft: 'auto' }}
          >
            Push token
          </PrimaryButton>
        </Card.Actions>
      </Card>
      <Spacer space={1} />
    </Fragment>
  );
}
