import { TactileModuleSwipe } from '@introcloud/api-client';
import { useDimensions } from '@introcloud/blocks';
import {
  AnimatePresence,
  motion,
  PanInfo,
  useAnimation,
  useMotionValue,
  useTransform,
} from 'framer-motion';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { Card, HelperText } from 'react-native-paper';
import { useIsMounted } from 'use-is-mounted';
import { Profile, SelfProfile } from './Profile';
import { SwipeCard } from './SwipeCard.web';

const maxSize = 300;

function useSwipe({ onSwiped }: { onSwiped(kind: 'like' | 'dislike'): void }) {
  const { width } = useDimensions();
  const animation = useAnimation();

  // Turn x and y into animatable values
  const x = useMotionValue(0);

  // Change the rotation value based on the x position
  const middle = width / 2;
  const rotateBoundary = width / 4;

  const handleDragEnd = useCallback(
    (event: DragEvent, info: PanInfo) => {
      // Get the x value of the drag event

      const dragX = info.offset.x;

      // Reset the animation value
      setTimeout(() => x.set(0), 1);

      // If dragged past a certain point to the right, stop tracking the x and y positions
      // Animate the card to fly to the right
      if (dragX > Math.max(maxSize / 2, Math.min(maxSize, rotateBoundary))) {
        x.stop();
        animation.start({
          left: middle + maxSize,
          opacity: 0,
          transition: { duration: 0.4 },
        });

        onSwiped('like');
      }

      // If dragged past a certain point to the left, stop tracking the x and y positions
      // Animate the card to fly to the left
      else if (
        dragX < -Math.max(maxSize / 2, Math.min(maxSize, rotateBoundary))
      ) {
        x.stop();
        animation.start({
          right: middle + maxSize,
          opacity: 0,
          transition: { duration: 0.4 },
        });

        onSwiped('dislike');
      }
    },
    [onSwiped, animation, x, width]
  );

  const animationPosition = useTransform(
    x,
    [
      -Math.max(maxSize / 2, Math.min(maxSize, rotateBoundary)),
      0,
      Math.max(maxSize / 2, Math.min(maxSize, rotateBoundary)),
    ],
    [-1, 0, 1]
  );

  const handleDrag = (event: DragEvent, info: PanInfo) => {
    // TODO: offset position in card
    x.set(info.offset.x);
  };

  return {
    // Make the frame draggable
    drag: 'x',
    dragConstraints: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    dragElastic: 1,
    onDrag: handleDrag,
    onDragEnd: handleDragEnd,

    // Add animations controls
    animate: animation,
    animationPosition,
    whileTap: {
      boxShadow: '0px 10px 20px 0px rgba(0, 0, 0, 0.05)',
      // scale: 1.1,
    },
  } as const;
}

function SwipeAbleCard_({
  index,
  onSwipe,
  selfProfile,
  cardType,
  ...profile
}: Profile & {
  index: number;
  cardType: NonNullable<TactileModuleSwipe['card']>['image'];
  selfProfile?: SelfProfile;
  onSwipe: (profile: Profile, kind: 'like' | 'dislike') => void;
}) {
  const onSwiped = useCallback(
    (kind: 'like' | 'dislike') => onSwipe(profile, kind),
    [profile, onSwipe]
  );

  const { animationPosition, ...motionProps } = useSwipe({
    onSwiped,
  });

  useEffect(() => {
    motionProps.animate.start('in');
  }, [motionProps.animate]);

  return (
    <motion.div
      {...motionProps}
      initial={{ opacity: 0, scale: 1, top: -100 }}
      exit={{ opacity: 0, scale: 0.5 }}
      variants={{
        in: {
          opacity: 1,
          scale: 1,
          top: 0,
          transition: { duration: 0.2, easing: 'easeOut', delay: index * 0.05 },
        },
      }}
      style={{
        width: 300,
        height: cardType === 'big' ? 390 : 300,
        position: 'absolute',
        alignSelf: 'center',
        cursor: 'grab',
      }}
      whileTap={{
        ...motionProps.whileTap,
        cursor: 'grabbing',
      }}
    >
      <SwipeCard
        {...profile}
        selfProfile={selfProfile}
        animationPosition={animationPosition}
        cardType={cardType}
      />
    </motion.div>
  );
}

/*
class SwipeAbleCard extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return <SwipeAbleCard_ {...this.props} />;
  }
}
*/

const SwipeAbleCard = memo(SwipeAbleCard_);

export function Profiles_({
  profiles,
  onSwipe: onReportSwipe,
  showCount,
  cardType,
  selfProfile,
}: {
  profiles: Profile[];
  cardType: NonNullable<TactileModuleSwipe['card']>['image'];

  onSwipe: (profile: Profile, kind: 'like' | 'dislike') => void;
  selfProfile?: SelfProfile;
  showCount?: boolean;
}) {
  const [swipeable, setSwipable] = useState(profiles);
  const onSwipe = useCallback(
    (profile: Profile, kind: 'like' | 'dislike') => {
      onReportSwipe(profile, kind);
      setSwipable((prev) => prev.filter((q) => q.id !== profile.id));
    },
    [onReportSwipe]
  );
  const isMounted = useIsMounted();

  useEffect(() => {
    setSwipable(profiles.slice());
  }, [profiles]);

  const count = swipeable.length;

  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        maxWidth: '100%',
      }}
    >
      <View
        style={{
          flex: 1,
          margin: 8,
          zIndex: 1,
          maxWidth: 400,
          maxHeight: 400,
          width: '100%',
          height: '100%',
          padding: 16,
        }}
      >
        {isMounted.current ? (
          <AnimatePresence>
            {profiles.map((profile, index) => (
              <SwipeAbleCard
                key={profile.id}
                index={index}
                onSwipe={onSwipe}
                selfProfile={selfProfile}
                cardType={cardType}
                {...profile}
              />
            ))}
          </AnimatePresence>
        ) : null}
      </View>
      {showCount && count > 0 ? (
        <Card style={{ padding: 8, margin: 16 }}>
          <HelperText type="info">
            {count === 1 ? 'Last one' : `${count} to go`}
          </HelperText>
        </Card>
      ) : null}
    </View>
  );
}

export const Profiles = memo(Profiles_);
