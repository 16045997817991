import {
  fetchApplicationNews,
  TactileNewsItem,
  TactileNewsItems,
} from '@introcloud/api-client';
import { useIsFocused } from '@react-navigation/core';
import { FetchMediaError } from 'fetch-media';
import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useIsMounted } from 'use-is-mounted';
import { NotReady } from '../core/errors/NotReady';
import {
  AnyMemoryValue,
  StoredMemoryValue,
  useMutableMemoryValue,
} from '../storage';
import { useAbortController } from './useAbortController';
import {
  runOnLogout,
  useEndpoint,
  useSafeAuthorization,
} from './useAuthentication';

const NEWS: AnyMemoryValue<TactileNewsItems | null> = new StoredMemoryValue<TactileNewsItems>(
  'application.news.v1'
);

// TODO: consider secure storage

runOnLogout(() => {
  NEWS.emit(null);
});

export function useNews({ enabled = true }: { enabled?: boolean } = {}) {
  const isFocused = useIsFocused();
  const authorization = useSafeAuthorization();
  const endpoint = useEndpoint();
  const isMounted = useIsMounted();
  const [storedNews, setStoredNews] = useMutableMemoryValue(NEWS);

  const abortable = useAbortController();

  const fetcher = useCallback(() => {
    if (!authorization || !endpoint) {
      throw new NotReady();
    }

    const ac = abortable();

    async function call() {
      const result = await fetchApplicationNews(
        endpoint,
        authorization!,
        ac.signal,
        __DEV__
      );

      const sorted = result.sort((a, b) =>
        b.meta.publish.at.dateISO.localeCompare(a.meta.publish.at.dateISO)
      );

      isMounted.current && setStoredNews(sorted);

      return sorted;
    }

    const cancellable = call();

    // This is a non-standard property on a promise, so the error here needs to
    // be ignored. However, react-query will check this non-standard property
    // and use it if it's available.
    //
    // @ts-ignore
    cancellable.cancel = () => {
      ac && ac.abort();
    };

    return cancellable;
  }, [authorization, endpoint, setStoredNews, abortable]);

  const { data: news, error, ...others } = useQuery<
    readonly TactileNewsItem[] | null,
    FetchMediaError | NotReady
  >(['application', 'news'], fetcher, {
    placeholderData: storedNews,
    enabled: enabled && isFocused && !!(authorization && endpoint),
    staleTime: 5 * 60 * 1000,
  });

  return {
    data: news,
    error,
    reload: others.refetch,
    loading: others.isLoading,
    refreshing: others.isFetching && !others.isLoading,
    ...others,
  };
}
