import { MaterialCommunityIcons } from '@expo/vector-icons';
import React from 'react';
import { PanGestureHandler, State } from 'react-native-gesture-handler';
import Animated, {
  abs,
  add,
  call,
  cond,
  divide,
  eq,
  greaterThan,
  max,
  min,
  multiply,
  not,
  onChange,
  or,
  round,
  set,
  startClock,
  stopClock,
  useCode,
} from 'react-native-reanimated';
import {
  diff,
  useClock,
  usePanGestureHandler,
  useValues,
  withSpring,
} from 'react-native-redash';
import { useGameMapInteraction } from './GameMapInteraction';
import { useViewport } from './ViewPort';

const SPRING_CONFIG: Omit<Animated.SpringConfig, 'toValue'> = {
  mass: 5,
  damping: 200,
  stiffness: 1000,
  overshootClamping: false,
  restSpeedThreshold: 2,
  restDisplacementThreshold: 2,
};

const MIN_DISTANCE = 20;
const TILES_PER_SECOND = 2;

export function Nipple() {
  const { center, shape } = useViewport();
  const { localX, localY, blockedTiles } = useGameMapInteraction();

  const {
    position,
    translation,
    velocity,
    state,
    gestureHandler,
  } = usePanGestureHandler();

  const snapPointsX = useValues(0);
  const snapPointsY = useValues(0);

  const translateX = withSpring({
    value: translation.x,
    velocity: velocity.x,
    state,
    snapPoints: snapPointsX,
    config: SPRING_CONFIG,
  });

  const translateY = withSpring({
    value: translation.y,
    velocity: velocity.y,
    state,
    snapPoints: snapPointsY,
    config: SPRING_CONFIG,
  });

  const transform = [{ translateX }, { translateY }];

  const clock = useClock();
  const dt = min(0.5, divide(diff(clock), 1000));

  const [
    isBlocked,
    upIsBlocked,
    leftIsBlocked,
    rightIsBlocked,
    downIsBlocked,
    tileIndex,
  ] = useValues<0 | 1>(0, 0, 0, 0, 0, 0);

  const width = shape.right + 1;

  useCode(
    () => [
      dt,
      set(tileIndex, add(multiply(round(localY), width), round(localX))),
      onChange(
        tileIndex,
        call([tileIndex, round(localX), round(localY)], ([index, x, y]) => {
          if (!blockedTiles) {
            return;
          }

          isBlocked.setValue(blockedTiles[index] || false ? 1 : 0);

          const nextLeftX = Math.round(x - 1) + Math.round(y) * width;
          const nextRightX = Math.round(x + 1) + Math.round(y) * width;
          const nextUpY = Math.round(x) + Math.round(y - 1) * width;
          const nextDownY = Math.round(x) + Math.round(y + 1) * width;

          // Left is blocked if at left boundary or the tile on the left side is blocked
          leftIsBlocked.setValue(
            x === 0 || blockedTiles[nextLeftX] || false ? 1 : 0
          );

          // Right is blocked if at right boundary or the tile on the right side is blocked
          rightIsBlocked.setValue(
            x === shape.right || blockedTiles[nextRightX] || false ? 1 : 0
          );

          // up is blocked if at top boundary or the tile on the top side is blocked
          upIsBlocked.setValue(
            y === 0 || blockedTiles[nextUpY] || false ? 1 : 0
          );

          // Down is blocked if at bottom boundary or the tile on the bottom side is blocked
          downIsBlocked.setValue(
            x === shape.bottom || blockedTiles[nextDownY] || false ? 1 : 0
          );
        })
      ),
    ],
    [
      dt,
      isBlocked,
      upIsBlocked,
      leftIsBlocked,
      rightIsBlocked,
      downIsBlocked,
      localX,
      localY,
      blockedTiles,
      width,
      shape,
    ]
  );

  useCode(() => {
    return cond(
      eq(state, State.ACTIVE),
      [
        startClock(clock),
        cond(
          greaterThan(abs(translateX), abs(translateY)),
          [
            // horizontal move
            cond(greaterThan(abs(translateX), MIN_DISTANCE), [
              cond(
                greaterThan(translateX, 0),
                [
                  cond(
                    or(
                      isBlocked,
                      not(rightIsBlocked),
                      eq(
                        round(localX),
                        round(add(localX, multiply(dt, TILES_PER_SECOND)))
                      )
                    ),
                    [
                      // move right,
                      set(
                        localX,
                        min(
                          shape.right,
                          add(localX, multiply(dt, TILES_PER_SECOND))
                        )
                      ),
                      set(center.x, localX),
                    ]
                  ),
                ],
                [
                  cond(
                    or(
                      isBlocked,
                      not(leftIsBlocked),
                      eq(
                        round(localX),
                        round(add(localX, multiply(dt, -TILES_PER_SECOND)))
                      )
                    ),
                    [
                      // move left
                      set(
                        localX,
                        max(
                          shape.left,
                          add(localX, multiply(dt, -TILES_PER_SECOND))
                        )
                      ),
                      set(center.x, localX),
                    ]
                  ),
                ]
              ),
            ]),
          ],
          [
            // vertical move
            cond(greaterThan(abs(translateY), MIN_DISTANCE), [
              cond(
                greaterThan(translateY, 0),
                [
                  cond(
                    or(
                      isBlocked,
                      not(downIsBlocked),
                      eq(
                        round(localY),
                        round(add(localY, multiply(dt, TILES_PER_SECOND)))
                      )
                    ),
                    [
                      // move bottom,
                      set(
                        localY,
                        min(
                          shape.bottom,
                          add(localY, multiply(dt, TILES_PER_SECOND))
                        )
                      ),
                      set(center.y, localY),
                    ]
                  ),
                ],
                [
                  cond(
                    or(
                      isBlocked,
                      not(upIsBlocked),
                      eq(
                        round(localY),
                        round(add(localY, multiply(dt, -TILES_PER_SECOND)))
                      )
                    ),
                    [
                      // move top
                      set(
                        localY,
                        max(
                          shape.top,
                          add(localY, multiply(dt, -TILES_PER_SECOND))
                        )
                      ),
                      set(center.y, localY),
                    ]
                  ),
                ]
              ),
            ]),
          ]
        ),
        dt,
      ],
      [stopClock(clock)]
    );
  }, [
    localX,
    localY,
    translateX,
    translateY,
    shape.bottom,
    isBlocked,
    isBlocked,
    upIsBlocked,
    leftIsBlocked,
    rightIsBlocked,
    downIsBlocked,
  ]);

  return (
    <PanGestureHandler {...gestureHandler}>
      <Animated.View
        style={{
          position: 'absolute',
          bottom: 56,
          left: 56,
          width: 72,
          height: 72,
          backgroundColor: 'rgba(127, 0, 127, .5)',
          borderRadius: 72 / 2,
          zIndex: 4,
        }}
      >
        <Animated.View
          style={{
            position: 'absolute',
            bottom: 16,
            left: 16,
            width: 40,
            height: 40,
            borderRadius: 40 / 2,
            backgroundColor: 'rgba(127, 127, 127, .8)',
            padding: 4,
            transform,
          }}
        >
          <MaterialCommunityIcons
            name="gamepad"
            size={32}
            color="rgba(0, 0, 0, 0.5)"
          />
        </Animated.View>
      </Animated.View>
    </PanGestureHandler>
  );
}
