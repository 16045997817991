import { TactileGoal } from '@introcloud/api-client';
import { PrimaryButton, TextButton } from '@introcloud/blocks';
import React, { Fragment, useState } from 'react';
import { View } from 'react-native';
import { List, RadioButton } from 'react-native-paper';

import { DialogContentProps } from './types';

export function RadioDialogContent({
  answers,
  loading,
  onDismiss,
  onSubmit,
}: {
  answers: TactileGoal['answers'];
} & DialogContentProps) {
  const [selectedAnswer, setSelectedAnswer] = useState<{
    value: string;
  } | null>(null);

  return (
    <Fragment>
      <View style={{ marginHorizontal: -16 }}>
        <List.Subheader>Select one</List.Subheader>
        <RadioButton.Group
          onValueChange={(value) => setSelectedAnswer({ value })}
          value={selectedAnswer?.value || ''}
        >
          {answers.map((answer) => (
            <RadioButton.Item
              key={answer._id}
              label={answer.label}
              value={answer._id}
            />
          ))}
        </RadioButton.Group>
      </View>
      <View style={{ flexDirection: 'row' }}>
        <PrimaryButton
          icon="send"
          onPress={() => onSubmit(selectedAnswer!.value)}
          disabled={loading || !selectedAnswer}
          loading={loading}
          style={{ marginRight: 'auto', marginTop: 16 }}
        >
          Submit
        </PrimaryButton>
        <TextButton
          onPress={onDismiss}
          style={{ marginLeft: 8, marginTop: 16 }}
        >
          Close
        </TextButton>
      </View>
    </Fragment>
  );
}
