import { ActionListItemProps, useDestination } from '@introcloud/blocks';
import React from 'react';
import { List } from 'react-native-paper';

function NewsListItem_({
  label,
  description,
  image,
  destination,
}: { image: string; description: string } & ActionListItemProps) {
  const onPress = useDestination(destination);

  return (
    <List.Item
      title={label}
      description={description}
      descriptionNumberOfLines={2}
      descriptionStyle={{ lineHeight: 18, fontSize: 12, marginTop: 8 }}
      right={(props) => (
        <List.Icon
          icon="chevron-right"
          {...props}
          style={{ ...props.style, alignSelf: 'center' }}
        />
      )}
      onPress={onPress}
    />
  );
}

export const NewsListItem = React.memo(NewsListItem_);
