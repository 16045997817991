import { fetchApplicationUser, TactileUser } from '@introcloud/api-client';
import { FetchMediaError } from 'fetch-media';
import merge from 'lodash.merge';
import { useCallback } from 'react';
import { Platform } from 'react-native';
import { useQuery } from 'react-query';
import { useIsMounted } from 'use-is-mounted';

import { NotReady } from '../core/errors/NotReady';
import {
  AnyMemoryValue,
  SecureStoredMemoryValue,
  StoredMemoryValue,
  useMutableMemoryValue,
} from '../storage';
import { useAbortController } from './useAbortController';
import {
  runOnLogout,
  useEndpoint,
  useSafeAuthorization,
} from './useAuthentication';

const USER: AnyMemoryValue<TactileUser | null> = Platform.select({
  web: new StoredMemoryValue<TactileUser>(
    'application.user.v1.web'
  ) as AnyMemoryValue<TactileUser>,
  default: new SecureStoredMemoryValue<TactileUser>(
    'application.user.v1.native'
  ) as AnyMemoryValue<TactileUser>,
});

runOnLogout(() => {
  USER.emit(null);
});

export function useUser({ enabled = true }: { enabled?: boolean } = {}) {
  const authorization = useSafeAuthorization();
  const endpoint = useEndpoint();
  const isMounted = useIsMounted();
  const abortable = useAbortController();

  const [storedUser, setStoredUser] = useMutableMemoryValue(USER);

  const fetcher = useCallback(() => {
    if (!endpoint || !authorization) {
      throw new NotReady();
    }

    const ac = abortable();

    async function call() {
      const result = await fetchApplicationUser(
        endpoint,
        authorization!,
        ac.signal,
        __DEV__
      );

      const {
        _id,
        name: { full, first, middle, last },
        image: { profile },
        email: { value, anonymous },
        isAdmin,
        accessRef,
        chatId,
      } = merge({ name: {}, image: {}, email: {} }, result);

      const finalUser: TactileUser = {
        _id,
        name: { full, first, middle, last },
        image: { profile },
        email: { value, anonymous },
        isAdmin,
        accessRef,
        chatId,
      };

      isMounted.current && setStoredUser(finalUser);

      return finalUser;
    }

    const cancellable = call();

    // This is a non-standard property on a promise, so the error here needs to
    // be ignored. However, react-query will check this non-standard property
    // and use it if it's available.
    //
    // @ts-ignore
    cancellable.cancel = () => {
      ac && ac.abort();
    };

    return cancellable;
  }, [endpoint, authorization]);

  const { data: user, error, refetch, isFetching, ...others } = useQuery<
    TactileUser | null,
    FetchMediaError | NotReady
  >(['application', 'user'], fetcher, {
    placeholderData: storedUser,
    enabled: enabled && !!(authorization && endpoint),
    staleTime: 60 * 1000,
  });

  return {
    data: user,
    error,
    reload: refetch,
    loading: others.isLoading,
    refreshing: isFetching,
    ...others,
  };
}
