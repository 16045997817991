import { TabIdentifier, tabName, useTabs } from '@introcloud/tabs';
import { useMemo } from 'react';

export function useTabColor(identifier: TabIdentifier): string | undefined {
  const tabs = useTabs();
  const tab = useMemo(
    () => tabs.find((tab) => tab.name === tabName(identifier)),
    [identifier, tabs]
  );
  return tab?.color;
}
