import { TactileGroupUser } from '@introcloud/api-client';
import React, { Fragment, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { List, Paragraph } from 'react-native-paper';

import { useGroups } from '../hooks/useGroup';
import { ContactChip } from '../profile/ContactChip';
import { useUser } from '../hooks/useUser';

export function Contacts() {
  return (
    <Fragment>
      <List.Subheader style={styles.backgroundHeader}>
        Quick contacts
      </List.Subheader>

      <ContactChips />
    </Fragment>
  );
}

function ContactChips() {
  const { data: userSelf } = useUser();
  const { groups } = useGroups();

  const members = useMemo(
    () =>
      (groups || [])
        .reduce(
          (result, group) => result.concat(group.users),
          [] as TactileGroupUser[]
        )
        // Remove duplicates and self
        .filter(
          (item, index, self) =>
            self.findIndex((first) => first._id === item._id) === index &&
            userSelf?._id !== item._id
        )
        // Remove invalid phonen numbers
        .map((member) => {
          if ((member.mobile.valid && member.mobile.value?.length) || 0 <= 5) {
            return { ...member, mobile: { ...member.mobile, valid: false } };
          }
          return member;
        }),
    [groups]
  );

  if (groups === undefined) {
    return null;
  }

  if (groups?.length === 0 || groups === null) {
    return (
      <Paragraph style={{ marginHorizontal: 16 }}>
        Once you've been assigned to a group, everyone in it will appear here.
        Check back later!
      </Paragraph>
    );
  }

  if (members.length === 0) {
    return (
      <Paragraph style={{ marginHorizontal: 16 }}>
        At this moment your group contacts are not available.
      </Paragraph>
    );
  }

  return (
    <View style={styles.container}>
      {members.map((member) => {
        return <ContactChip contact={member} key={member._id} />;
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: 0,
    marginLeft: 12,
    marginRight: 12,
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  backgroundHeader: {
    marginTop: 0,
  },
});
