import {
  createStackNavigator,
  TransitionPresets,
} from '@react-navigation/stack';
import React from 'react';
import { useTheme } from 'react-native-paper';

import { EmbedScreen } from './EmbedScreen';
import { PageScreen } from './PageScreen';

const Stack = createStackNavigator<{
  Page: { id: string };
  Embed: { id: string; blockId: string };
}>();

export function PageStack() {
  const {
    colors: { primary },
  } = useTheme();

  return (
    <Stack.Navigator
      mode="modal"
      screenOptions={{
        headerShown: false,
        // stackAnimation: 'fade',
      }}
    >
      <Stack.Screen name="Page" component={PageScreen} />
      <Stack.Screen
        name="Embed"
        component={EmbedScreen}
        options={{
          cardStyle: { backgroundColor: primary },
        }}
      />
    </Stack.Navigator>
  );
}
