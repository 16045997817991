import { useWindowWidth } from '@introcloud/blocks';
import { LinearGradient } from 'expo-linear-gradient';
import { AllHtmlEntities } from 'html-entities';
import React from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import { Card, useTheme } from 'react-native-paper';
import { GelegraafItem } from './useGelegraaf';

const entities = new AllHtmlEntities();

const styles = StyleSheet.create({
  // text-shadow: 1px 2px 0 rgba(0,0,0,0.47);
  headlineWrapper: {
    position: 'absolute',
    left: 8,
    right: 8,
    bottom: 8,
  },

  headline: {
    fontSize: 16,
    lineHeight: 22,
    fontWeight: 'bold',
    color: 'white',
    textShadowColor: 'rgba(0, 0, 0, 0.47)',
    textShadowOffset: { width: 1, height: 2 },
    textShadowRadius: 2,
  },

  scrim: {
    position: 'absolute',
    left: 0,
    top: 0,
    backgroundColor: 'rgba(0, 0, 0, .25)',
    right: 0,
    bottom: 0,
    borderRadius: 4,
  },

  image: {
    width: '100%',
    height: '100%',
  },

  touch: {
    margin: 0,
    padding: 0,
    position: 'relative',
    borderRadius: 4,
  },
});

export interface FeaturedHeadlineProps {
  article: GelegraafItem;
  even: boolean;
  onGotoArticle(): void;
}

export function FeaturedHeadline({
  article,
  onGotoArticle,
}: FeaturedHeadlineProps) {
  const { title, featured_media } = article;

  const image =
    (featured_media?.media_details?.sizes &&
      featured_media.media_details.sizes['speaker-thumb'] &&
      featured_media.media_details.sizes['speaker-thumb'].source_url) ||
    'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA';

  const size = (Math.min(720, useWindowWidth()) - 16) / 2 - 16;

  return (
    <Card
      onPress={onGotoArticle}
      style={{ width: size, height: size, marginHorizontal: 8 }}
    >
      <Image
        style={[
          styles.image,
          { borderRadius: useTheme().roundness, overflow: 'hidden' },
        ]}
        source={{ uri: image, width: 458, height: 458 }}
      />

      <LinearGradient
        colors={['rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, .32)']}
        style={StyleSheet.absoluteFill}
      />

      <View pointerEvents="none" style={styles.headlineWrapper}>
        <Text style={styles.headline}>
          {entities.decode((title || { rendered: '' }).rendered)}
        </Text>
      </View>
    </Card>
  );
}
