import { usePageData } from '@introcloud/page';
import React, { useMemo, useRef } from 'react';
import { View } from 'react-native';
import {
  ActivityIndicator,
  HelperText,
  useTheme,
  ThemeProvider,
} from 'react-native-paper';

import { BlockProvision } from '../core/BlockProvision';
import { FallbackRoute, Header, ValidRoutes } from '../core/Header';
import { PAGE_COLORS_ENABLED } from '../features';
import { PageViewer } from './PageViewer';

export function BlockProvidedPage<T extends ValidRoutes>({
  page,
  hideBack,
  fallBack,
  colorOverride,
  screen,
}: PageWithContextProps<T> & {
  page: string | null;
  screen?: string;
}) {
  if (!page) {
    return <NotFound />;
  }

  return (
    <Page
      page={page}
      hideBack={hideBack}
      fallBack={fallBack}
      colorOverride={colorOverride}
      screen={screen}
    />
  );
}

export function Page<T extends ValidRoutes>({
  page,
  hideBack,
  fallBack,
  colorOverride,
  screen = 'Page',
}: PageWithContextProps<T> & { page: string; screen?: string }) {
  return (
    <BlockProvision screen={screen} page={page}>
      <PageWithContext
        hideBack={hideBack}
        fallBack={fallBack}
        colorOverride={colorOverride}
      />
    </BlockProvision>
  );
}

export interface PageWithContextProps<T extends ValidRoutes> {
  hideBack?: boolean;
  fallBack?: FallbackRoute<T>;
  colorOverride?: string;
}

export function PageWithContext<T extends ValidRoutes>({
  hideBack,
  fallBack,
  colorOverride,
}: PageWithContextProps<T>) {
  const { page } = usePageData();
  const hadToLoadRef = useRef(false);
  const theme = useTheme();
  const pageTheme = useMemo(() => {
    if (!PAGE_COLORS_ENABLED || !page?.module?.application?.colors) {
      return theme;
    }

    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary: page.module.application.colors.primary || theme.colors.primary,
        accent: page.module.application.colors.accent || theme.colors.accent,
      },
    };
  }, [theme, page]);

  if (!page) {
    hadToLoadRef.current = true;

    return (
      <LoadingPage
        hideBack={hideBack}
        fallBack={fallBack}
        colorOverride={colorOverride}
      />
    );
  }

  return (
    <ThemeProvider theme={pageTheme}>
      <PageViewer
        hideBack={hideBack}
        hadToLoad={hadToLoadRef.current}
        fallBack={fallBack}
        colorOverride={colorOverride}
      />
    </ThemeProvider>
  );
}

export function LoadingPage<T extends ValidRoutes>({
  hideBack,
  fallBack,
  colorOverride,
}: {
  hideBack?: boolean;
  fallBack?: FallbackRoute<T>;
  colorOverride?: string;
}) {
  return (
    <View style={{ flex: 1, overflow: 'hidden' }}>
      <Header
        title={' '}
        subTitle={undefined}
        hideBack={hideBack}
        backFallback={fallBack}
        colorOverride={colorOverride}
      />

      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ActivityIndicator />
      </View>
    </View>
  );
}

export function NotFound() {
  return (
    <HelperText type="error">
      That content is not available or does not exist.
    </HelperText>
  );
}
