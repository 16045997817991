import { TactileGameMapPlace } from '@introcloud/api-client';
import { useBlockData } from '@introcloud/blocks';
import React from 'react';
import { Image } from 'react-native';
import { Card } from 'react-native-paper';
import Animated from 'react-native-reanimated';
import { useGameMapInteraction } from './GameMapInteraction';
import { useViewport } from './ViewPort';

const DEBUG_MAP = __DEV__ && true;

export function MapPlace({
  _id,
  kind,
  shape,
  image,
}: TactileGameMapPlace &
  Partial<{ image: Record<string, unknown> | { background: string } }>) {
  switch (kind) {
    case 'blocked': {
      return <BlockedPlace {...shape} />;
    }

    case 'place': {
      return <VisitablePlace {...shape} _id={_id} image={image} />;
    }

    case 'background': {
      return <BackgroundPlace {...shape} image={image} />;
    }

    default: {
      return null;
    }
  }
}

function BlockedPlace({ x, y, width, height }: TactileGameMapPlace['shape']) {
  const { displayFromCoords, displayFromSize, transform } = useViewport();

  if (!DEBUG_MAP) {
    return null;
  }

  return (
    <Animated.View
      pointerEvents="none"
      style={{
        transform,
        position: 'absolute',
        ...displayFromCoords(x, y),
        ...displayFromSize(width, height),
        backgroundColor: DEBUG_MAP ? 'rgba(0, 0, 0, .5)' : 'transparent',
      }}
    />
  );
}

function BackgroundPlace({
  x,
  y,
  width,
  height,
  image,
}: TactileGameMapPlace['shape'] &
  Partial<{ image: Record<string, unknown> | { background: string } }>) {
  const { displayFromCoords, displayFromSize, transform } = useViewport();
  const { getImageUrl } = useBlockData();

  const imageRatio = Math.round(width / height);
  return (
    <Animated.View
      pointerEvents="none"
      style={{
        position: 'absolute',
        ...displayFromCoords(x, y),
        ...displayFromSize(width, height),
        backgroundColor: DEBUG_MAP ? 'rgba(127, 127, 127, 0)' : 'transparent',
        padding: 0,
        margin: 0,
        overflow: 'hidden',
        borderRadius: 0,
        zIndex: 0,
        transform,
      }}
    >
      {image && image.background ? (
        <Image
          style={displayFromSize(width, height)}
          source={{
            width: imageRatio * 1440,
            height: 1440,
            uri: getImageUrl(image.background as string, 'icon_1440')!,
          }}
        />
      ) : null}
    </Animated.View>
  );
}

function VisitablePlace({
  _id,
  x,
  y,
  width,
  height,
  image,
}: TactileGameMapPlace['shape'] &
  Pick<TactileGameMapPlace, '_id'> &
  Partial<{ image: Record<string, unknown> | { background: string } }>) {
  const { displayFromCoords, displayFromSize, transform } = useViewport();
  const { getImageUrl } = useBlockData();

  const {
    selected: { select },
    localX,
    localY, // TODO something with blocking access or only clickable if close
  } = useGameMapInteraction();

  const imageRatio = Math.round(width / height);

  return (
    <Animated.View
      style={{
        position: 'absolute',
        ...displayFromCoords(x, y),
        ...displayFromSize(width, height),
        transform,
        overflow: 'hidden',
        zIndex: 1,
      }}
    >
      <Card
        elevation={0}
        onPress={() => {
          select(`mapPlace/${_id}`);
        }}
        style={{
          elevation: 0,
          padding: 0,
          margin: 0,
          overflow: 'hidden',
          borderRadius: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'transparent',
        }}
      >
        {image && image.background ? (
          <Image
            {...displayFromSize(width, height)}
            source={{
              width: imageRatio * 1440,
              height: 1440,
              uri: getImageUrl(image.background as string, 'icon_1440')!,
            }}
          />
        ) : null}
      </Card>
    </Animated.View>
  );
}
