import {
  fetchApplicationPage,
  fetchApplicationVideo,
  TactileVideo,
} from '@introcloud/api-client';
import { VideoStreamBlockOptions } from '@introcloud/page';
import { prepare } from '@introcloud/page/dist/blocks/VideoEmbedBlock';
import { KIND_VIDEO_STREAM } from '@introcloud/page/dist/Kinds';
import {
  lockAsync,
  OrientationLock,
  unlockAsync,
} from 'expo-screen-orientation';
import React, { useCallback, useMemo } from 'react';
import {
  Dimensions,
  Insets,
  Keyboard,
  KeyboardEvent,
  Platform,
  ScaledSize,
  StatusBar,
} from 'react-native';
import { Card, DarkTheme, ThemeProvider } from 'react-native-paper';
import Animated, {
  add,
  block,
  divide,
  Easing,
  Extrapolate,
  interpolate,
  min,
  multiply,
  Node,
  sub,
  Value,
} from 'react-native-reanimated';
import { withTimingTransition } from 'react-native-redash';
import { withSafeAreaInsets } from 'react-native-safe-area-context';

import { navigationRef } from '../core/RootNavigation';
import { NotReady } from '../core/errors/NotReady';
import { useAbortController } from '../hooks/useAbortController';
import { useEndpoint, useSafeAuthorization } from '../hooks/useAuthentication';
import { usePage } from '../hooks/usePage';
import { AnimatedVideo } from './AnimatedVideo';
import { PlayerVideo } from './PlayerContext';
import PlayerControls from './PlayerControls';
import { VideoContent } from './VideoContent';
import { useQuery } from 'react-query';
import { FetchMediaError } from 'fetch-media';

type VideoModalProps = {
  video: PlayerVideo;
  primary: string;
  withoutKeyboardHeight: number;
};

const { width: INITIAL_WIDTH, height: INITIAL_HEIGHT } = Dimensions.get(
  'window'
);

const MIN_HEIGHT = 64;

class VideoModal_ extends React.PureComponent<
  VideoModalProps & { insets: Insets }
> {
  width = new Value(INITIAL_WIDTH);
  height = new Value(
    INITIAL_HEIGHT +
      Platform.select({ android: StatusBar.currentHeight || 20, default: 0 })
  );
  keyboardHeight = new Value<number>(0);
  slideUpState = new Value<number>(1);
  active: boolean = true;

  midBound: Node<number>;
  upperBound: Node<number>;
  animation: Animated.Node<number>;

  constructor(props: VideoModalProps & { insets: Insets }) {
    super(props);
    this.midBound = sub(this.height, 132);
    this.upperBound = add(this.midBound, MIN_HEIGHT);

    const window = Dimensions.get('window');

    this.width.setValue(window.width);
    this.height.setValue(
      window.height +
        Platform.select({ android: StatusBar.currentHeight || 20, default: 0 })
    );

    this.animation = withTimingTransition(this.slideUpState, {
      duration: 300,
      easing: Easing.inOut(Easing.ease),
    });
  }

  componentDidMount() {
    Dimensions.addEventListener('change', this.onDimensionsChanged);

    Keyboard.addListener('keyboardWillShow', this.onKeyboardShow);
    Keyboard.addListener('keyboardWillHide', this.onKeyboardHide);
    Keyboard.addListener('keyboardDidShow', this.onKeyboardShow);
    Keyboard.addListener('keyboardDidHide', this.onKeyboardHide);

    unlockAsync().catch(() => {});
  }

  componentWillUnmount() {
    Dimensions.removeEventListener('change', this.onDimensionsChanged);
    Keyboard.removeListener('keyboardWillShow', this.onKeyboardShow);
    Keyboard.removeListener('keyboardWillHide', this.onKeyboardHide);
    Keyboard.removeListener('keyboardDidShow', this.onKeyboardShow);
    Keyboard.removeListener('keyboardDidHide', this.onKeyboardHide);

    lockAsync(OrientationLock.PORTRAIT).catch(() => {});
  }

  onDimensionsChanged = ({ window }: { window: ScaledSize }) => {
    this.width.setValue(window.width);
    this.height.setValue(
      window.height +
        Platform.select({ android: StatusBar.currentHeight || 20, default: 0 })
    );
  };

  onKeyboardShow = ({ endCoordinates, ...p }: KeyboardEvent) => {
    const reportedHeight = endCoordinates.height;
    const calculatedDifference =
      Dimensions.get('window').height - this.props.withoutKeyboardHeight;

    this.keyboardHeight.setValue(
      Math.floor(reportedHeight + calculatedDifference)
    );
  };

  onKeyboardHide = ({ endCoordinates }: KeyboardEvent) => {
    this.keyboardHeight.setValue(0);
  };

  componentDidUpdate(prevProps: VideoModalProps) {
    if (prevProps !== this.props) {
      this.slideUp();
    }
  }

  slideUp = () => {
    this.slideUpState.setValue(1);
    this.active = true;
    unlockAsync().catch(() => {});
  };

  slideDown = () => {
    this.slideUpState.setValue(0);
    Keyboard.dismiss();

    this.active = false;
    lockAsync(OrientationLock.PORTRAIT).catch(() => {});
  };

  render() {
    const translateY = interpolate(this.animation, {
      inputRange: [0, 1],
      outputRange: [this.upperBound, 0],
    }); //add(y, offsetY2);

    const tY = block([
      interpolate(translateY, {
        inputRange: [0, this.midBound],
        outputRange: [0, this.midBound],
        extrapolate: Extrapolate.CLAMP,
      }),
    ]);

    const opacity = interpolate(translateY, {
      inputRange: [0, sub(this.midBound, 100)],
      outputRange: [1, 0],
      extrapolate: Extrapolate.CLAMP,
    });

    const statusBarHeight = interpolate(translateY, {
      inputRange: [0, sub(this.midBound, 100)],
      outputRange: [
        StatusBar.currentHeight || Platform.select({ web: 0, default: 20 }),
        0,
      ],
      extrapolate: Extrapolate.CLAMP,
    });

    const videoContainerWidth = interpolate(translateY, {
      inputRange: [0, this.midBound],
      outputRange: [this.width, sub(min(720, this.width), 16)],
      extrapolate: Extrapolate.CLAMP,
    });

    const videoWithMax = videoContainerWidth; // min(720, videoContainerWidth);

    const videoWidth = interpolate(translateY, {
      inputRange: [0, this.midBound, this.upperBound],
      outputRange: [
        videoWithMax,
        sub(videoWithMax, 16),
        divide(videoWithMax, 3),
      ],
      extrapolate: Extrapolate.CLAMP,
    });

    const videoHeight = interpolate(translateY, {
      inputRange: [0, this.midBound, this.upperBound],
      outputRange: [
        divide(videoWidth, 1.78),
        multiply(MIN_HEIGHT, 1.3),
        MIN_HEIGHT,
      ],
      extrapolate: Extrapolate.CLAMP,
    });

    const realVideoHeight = min(
      videoHeight,
      divide(min(720, videoWidth), 1.78)
    );

    const containerHeight = interpolate(translateY, {
      inputRange: [0, this.midBound],
      outputRange: [sub(sub(this.height, realVideoHeight), statusBarHeight), 0],
      extrapolate: Extrapolate.CLAMP,
    });

    const playerControlOpacity = interpolate(translateY, {
      inputRange: [this.midBound, this.upperBound],
      outputRange: [0, 1],
      extrapolate: Extrapolate.CLAMP,
    });

    return (
      <ThemeProvider theme={{ ...DarkTheme, mode: 'exact' }}>
        <NavigationListener onBack={this.slideDown} />
        <VideoResolver video={this.props.video}>
          {({ video: resolvedVideo, error, chat }) => (
            <Animated.View
              style={[
                {
                  alignItems: 'center',
                  transform: [{ translateY: tY }],
                  maxWidth: '100%',
                  maxHeight: Platform.select({ web: '100vh', default: '100%' }),
                  height: add(
                    containerHeight,
                    realVideoHeight,
                    statusBarHeight
                  ) /*,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,*/,
                },
              ]}
            >
              <Card
                elevation={8}
                style={{ justifyContent: 'center', height: '100%' }}
              >
                <Animated.View
                  style={{
                    backgroundColor: 'black',
                    height: statusBarHeight,
                    maxHeight: statusBarHeight,
                  }}
                />
                <Animated.View
                  style={{
                    width: videoContainerWidth,
                    height: realVideoHeight,
                  }}
                >
                  <Animated.View
                    style={{
                      opacity: playerControlOpacity,
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      right: 0,
                      left: videoWidth,
                    }}
                  >
                    <PlayerControls
                      title={
                        resolvedVideo?.name?.full ||
                        resolvedVideo?.page?.name.full ||
                        'Loading...'
                      }
                      onPress={this.slideUp}
                    />
                  </Animated.View>
                  <Animated.View
                    style={{
                      width: videoWidth,
                      height: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      overflow: 'hidden',
                    }}
                  >
                    <AnimatedVideo
                      source={prepare(
                        resolvedVideo.url || '',
                        resolvedVideo.kind || ('other' as any),
                        DarkTheme.colors.primary,
                        Platform.select({
                          web: false,
                          android: false,
                          ios: true,
                          default: false,
                        }),
                        true
                      )}
                      kind={resolvedVideo.kind || 'url'}
                      style={{
                        width: min(720, videoWidth),
                        height: '100%',
                      }}
                      shouldPlay
                    />
                  </Animated.View>
                </Animated.View>
                <Animated.View
                  style={{
                    width: videoContainerWidth,
                    flex: 1,
                    position: 'relative',
                    overflow: 'hidden',
                  }}
                >
                  <Animated.View
                    style={{
                      opacity,
                      flex: 1,
                      width: '100%',
                      position: 'relative',
                    }}
                  >
                    <VideoContent
                      video={resolvedVideo}
                      chat={chat}
                      onDismiss={this.slideDown}
                      keyboardAvoidingHeight={this.keyboardHeight}
                    />
                  </Animated.View>
                </Animated.View>
              </Card>
            </Animated.View>
          )}
        </VideoResolver>
      </ThemeProvider>
    );
  }
}

export const VideoModal__ = (withSafeAreaInsets(
  VideoModal_
) as unknown) as React.ComponentType<VideoModalProps>;

export const VideoModal = React.memo(VideoModal__);

function VideoResolver({
  video,
  children,
}: {
  video: PlayerVideo;
  children: (next: {
    error: Error | null;
    video: PlayerVideo;
    chat: VideoStreamBlockOptions['value']['chat'] | undefined;
  }) => JSX.Element;
}) {
  const endpoint = useEndpoint();
  const authorization = useSafeAuthorization();

  const getInfoById = useCallback(
    (pageId: string) =>
      fetchApplicationPage(
        pageId,
        endpoint,
        authorization || '',
        undefined,
        __DEV__
      ),
    [endpoint, authorization]
  );

  const { page: provided, error } = usePage(video.pageId, getInfoById);

  const page = provided || video.page;

  const actualVideoBlock = useMemo(
    (): VideoStreamBlockOptions | undefined =>
      (page?.content?.find(
        (block) => block.kind === KIND_VIDEO_STREAM
      ) as unknown) as VideoStreamBlockOptions,
    [page?.content]
  );

  const { video: actualVideo, chat } = useMemo(
    () => actualVideoBlock?.value || { video: undefined, chat: undefined },
    [actualVideoBlock]
  );

  const abortable = useAbortController();

  const fetcher = useCallback(
    (videoId: string) => {
      if (!endpoint || !authorization) {
        throw new NotReady();
      }

      const ac = abortable();

      const cancellable = fetchApplicationVideo(
        videoId,
        endpoint,
        authorization,
        ac.signal,
        __DEV__
      );

      // This is a non-standard property on a promise, so the error here needs to
      // be ignored. However, react-query will check this non-standard property
      // and use it if it's available.
      //
      // @ts-ignore
      cancellable.cancel = () => {
        ac && ac.abort();
      };

      return cancellable;
    },
    [endpoint, authorization]
  );

  const { error: videoError, data: videoData } = useQuery<
    TactileVideo,
    FetchMediaError
  >([actualVideo?.src, 'video'], () => fetcher(actualVideo!.src), {
    enabled: !!actualVideo?.src,
  });

  const filledVideo: PlayerVideo = {
    page,
    ...video,
    ...videoData,
  };

  return children({
    video: filledVideo,
    chat,
    error: error || videoError,
  });
}

function NavigationListener({ onBack }: { onBack: () => void }) {
  const navigation = navigationRef.current;

  React.useEffect(() => {
    if (!navigation) {
      return;
    }

    return navigation.addListener('state', () => {
      onBack();
    });
  }, [navigation]);

  return null;
}
