import { fetchApplicationGroups, TactileGroup } from '@introcloud/api-client';
import { useIsFocused } from '@react-navigation/core';
import { FetchMediaError } from 'fetch-media';
import { useCallback } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { useIsMounted } from 'use-is-mounted';
import { NotReady } from '../core/errors/NotReady';
import {
  AnyMemoryValue,
  StoredMemoryValue,
  useMutableMemoryValue,
} from '../storage';
import { useAbortController } from './useAbortController';
import {
  runOnLogout,
  useEndpoint,
  useSafeAuthorization,
} from './useAuthentication';

const GROUPS: AnyMemoryValue<
  readonly TactileGroup[] | null
> = new StoredMemoryValue<TactileGroup[]>('application.groups.v1');

// TODO: consider secure storage

runOnLogout(() => {
  GROUPS.emit(null);
});

export function useGroupsDetached({
  enabled = true,
  ...options
}: UseQueryOptions<
  readonly TactileGroup[] | null,
  FetchMediaError | Error
> = {}) {
  const authorization = useSafeAuthorization();
  const endpoint = useEndpoint();
  const isMounted = useIsMounted();
  const [storedGroups, setStoredGroups] = useMutableMemoryValue(GROUPS);

  const abortable = useAbortController();

  const fetcher = useCallback(async () => {
    if (!authorization || !endpoint) {
      throw new NotReady();
    }

    const ac = abortable();

    const result = await fetchApplicationGroups(
      endpoint,
      authorization,
      ac.signal,
      __DEV__
    );

    isMounted.current && setStoredGroups(result);

    return result;
  }, [authorization, endpoint, abortable, setStoredGroups]);

  const { data: groups, ...others } = useQuery(
    ['application', 'groups'],
    fetcher,
    {
      placeholderData: storedGroups,
      enabled: !!(authorization && endpoint) && enabled,
      staleTime: 10 * 60 * 1000,
      ...options,
    }
  );

  return { groups, ...others };
}

export function useGroups({ enabled = true }: { enabled?: boolean } = {}) {
  const isFocused = useIsFocused();
  const query = useGroupsDetached({ enabled: enabled && isFocused });

  return query;
}
