import { TactileGoal } from '@introcloud/api-client';
import { PrimaryButton, TextButton } from '@introcloud/blocks';
import { Video } from 'expo-av';
import {
  MediaLibraryPermissionResponse,
  launchImageLibraryAsync,
  MediaTypeOptions,
  PermissionStatus,
  requestMediaLibraryPermissionsAsync,
  VideoExportPreset,
} from 'expo-image-picker';
import React, { Fragment, useCallback, useState } from 'react';
import { Image, Platform, View } from 'react-native';
import { Caption, Card, IconButton, useTheme } from 'react-native-paper';
import { useIsMounted } from 'use-is-mounted';

import { DialogContentProps } from './types';

async function maybeRequestPermission(): Promise<MediaLibraryPermissionResponse> {
  if (Platform.OS === 'ios') {
    return requestMediaLibraryPermissionsAsync();
  }

  return { status: PermissionStatus.GRANTED } as MediaLibraryPermissionResponse;
}

function convertToBlobFile(image: string) {
  return fetch(image).then((res) => res.blob());
  // .then((blob) => new File([blob], 'answer'));
}

export function FileDialogContent({
  kind,
  loading,
  onDismiss,
  onSubmit,
  formDataKey = 'answer',
}: DialogContentProps & {
  kind: TactileGoal['kind'] | 'video';
  formDataKey?: string;
}) {
  const { dark } = useTheme();
  const [pickedResource, setPickedResource] = useState<{
    content: string | Blob | File;
    preview: string;
  } | null>(null);
  const isMounted = useIsMounted();

  const [fileLoading, setFileLoading] = useState(false);

  const openFilePicker = useCallback(() => {
    if (Platform.OS !== 'web') {
      setFileLoading(true);
    }

    maybeRequestPermission()
      .then(({ status }) => {
        if (status === 'granted' && isMounted.current) {
          return launchImageLibraryAsync({
            mediaTypes:
              kind === 'image'
                ? MediaTypeOptions.Images
                : MediaTypeOptions.Videos,
            allowsEditing: true,
            base64: Platform.OS === 'web', // turn off for now
            videoExportPreset: VideoExportPreset.MediumQuality,
          });
        }

        return null;
      })
      .then((result) => {
        if (!isMounted.current) {
          return;
        }

        if (result?.cancelled || !result) {
          setFileLoading(false);
          return;
        }

        if (result.base64) {
          setPickedResource({
            content: `data:image/jpeg;base64,${result.base64}`,
            preview: result.uri,
          });
          setFileLoading(false);
          return;
        }

        if (kind === 'image' && Platform.OS === 'web') {
          setPickedResource({ content: result.uri, preview: result.uri });
          setFileLoading(false);
          return;
        }

        setFileLoading(false);
        return setPickedResource({ content: result.uri, preview: result.uri });
      })
      .catch((err) => {
        console.log(err);
        if (!isMounted.current) {
          return;
        }
        setFileLoading(false);
      });
  }, []);

  return (
    <Fragment>
      <Card
        style={{
          width: 200,
          height: 200,
          backgroundColor: dark ? '#121212' : '#eee',
          padding: 16,
        }}
        onPress={openFilePicker}
      >
        {pickedResource ? (
          <Preview kind={kind as any} preview={pickedResource.preview} />
        ) : (
          <Empty kind={kind as any} />
        )}
      </Card>
      <View style={{ flexDirection: 'row' }}>
        <PrimaryButton
          icon="send"
          onPress={() => {
            const { content } = pickedResource || {};
            if (!content) {
              return;
            }

            // It's base64, so we need to convert it
            if (
              typeof content === 'string' &&
              (content!.startsWith('data:image') ||
                content!.startsWith('data:video'))
            ) {
              convertToBlobFile(content)
                .then((blobFile) => {
                  if (!isMounted.current) {
                    return;
                  }
                  const formData = new FormData();
                  formData.append(formDataKey, blobFile, formDataKey);
                  onSubmit(formData);
                })
                .catch(() => {});
              return;
            }

            if (typeof content === 'string' && content.startsWith('file:')) {
              const uriParts = content.split('.');
              const fileType = uriParts[uriParts.length - 1];

              const formData = new FormData();
              formData.append(formDataKey, {
                uri: content,
                name: `${formDataKey}.${fileType}`,
                type: `${kind}/${fileType}`,
              } as any);

              return onSubmit(formData);
            }

            console.error("Don't know how to handle the content");
          }}
          disabled={loading || !pickedResource || fileLoading}
          loading={loading || fileLoading}
          style={{ marginRight: 'auto', marginTop: 16 }}
        >
          Submit
        </PrimaryButton>
        <TextButton
          onPress={onDismiss}
          style={{ marginLeft: 8, marginTop: 16 }}
        >
          Close
        </TextButton>
      </View>
    </Fragment>
  );
}

function Empty({ kind }: { kind: 'image' | 'video' }) {
  const { dark } = useTheme();

  const label = kind === 'image' ? 'picture' : 'video';

  return (
    <View
      style={{
        width: 200 - 32,
        height: 200 - 32,
        borderRadius: 4,
        borderWidth: 2,
        borderStyle: 'dotted',
        borderColor: dark ? 'rgba(255, 255, 255, .5)' : 'rgba(0, 0, 0, .5)',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <IconButton
        icon="camera"
        color={dark ? 'rgba(255, 255, 255, .8)' : 'rgba(0, 0, 0, 0.8)'}
      />
      <Caption>Add a {label}</Caption>
    </View>
  );
}

function Preview({
  kind,
  preview,
}: {
  kind: 'image' | 'video';
  preview: string;
}) {
  switch (kind) {
    case 'image': {
      return (
        <Image
          style={{
            width: 200 - 32,
            height: 200 - 32,
            borderRadius: 4,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          source={{
            uri: preview,
            width: 200 - 32,
            height: 200 - 32,
          }}
          resizeMode="contain"
        />
      );
    }
    case 'video': {
      if (Platform.OS === 'web') {
        return (
          <video
            src={preview}
            style={{
              width: 200 - 32,
              height: 200 - 32,
              borderRadius: 4,
              justifyContent: 'center',
              alignItems: 'center',
            }}
            autoPlay
          />
        );
      }

      return (
        <Video
          source={{ uri: preview }}
          style={{
            width: 200 - 32,
            height: 200 - 32,
            borderRadius: 4,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          shouldPlay
          resizeMode="contain"
        />
      );
    }
  }

  return null;
}
