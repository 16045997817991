import { fetchApplication, TactileCompany } from '@introcloud/api-client';
import dlv from 'dlv';
import Constants from 'expo-constants';
import { useMemoryValue, useMutableMemoryValue } from 'expo-use-memory-value';
import { fetchMedia, FetchMediaError, JsonError } from 'fetch-media';
import React, {
  createContext,
  createElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Platform } from 'react-native';
import { UseMutateAsyncFunction, useMutation } from 'react-query';
import { useIsMounted } from 'use-is-mounted';
import { Analytics } from '../analytics';
import { MULTI_COMPANY_ENABLED } from '../features';
import {
  AnyMemoryValue,
  AnyValue,
  NoValue,
  SecureStoredMemoryValue,
  Serializable,
  StoredMemoryValue,
  UndeterminedValue,
} from '../storage';
import { getSafeAbortController } from '../utils';
import { useAbortController } from './useAbortController';
import { COMPANY, useMutableCompany } from './useCompany';
import { useForceUpdate } from './useForceUpdate';

export interface SelectCompany {
  domain: string;
  domainFull: string | undefined;
  name: {
    full: string;
  };
}

export type Permit = {
  domainFull: string;
  token: string;
  expiresAt: number;
};

interface PermitRequest {
  email: string;
  password: string;
}

const AUTHENTICATION: AnyMemoryValue<Permit | null> = Platform.select({
  web: new StoredMemoryValue<Permit>(
    'authentication.v1.web'
  ) as AnyMemoryValue<Permit>,
  default: new SecureStoredMemoryValue<Permit>(
    'authentication.v1.native'
  ) as AnyMemoryValue<Permit>,
});

const AuthenticationContext = createContext<{
  authentication: AnyValue<Permit | null>;
  set: (next: AnyValue<Permit | null>) => Promise<void>;
}>({
  authentication: undefined,
  set: () => Promise.reject(new Error('not ready')),
});

const ACCEPT = 'application/json';
const CONTENT_TYPE = 'application/json; charset=utf-8';
const BASE_ENDPOINT: string = Constants.manifest.extra.endpoint;

function makeAnonymousLogin() {
  return {
    expiresAt: new Date().getTime(),
    domainFull: BASE_ENDPOINT.split('/api')[0],
    token: '__fake',
  };
}

function assert<T extends Serializable>(
  value: AnyValue<T> | NoValue
): NonNullable<T> {
  if (__DEV__ && !value) {
    throw new Error(
      `The value passed in assert should have been set, actual ${typeof value}.
      You should guard for this higher up.

      When using the authentication hooks, check if the user is authenticated
      using useIsAuthenticated(). If this returns false, it is NOT safe to use
      useAuthorization().`
    );
  }

  return value!;
}

interface Authenticate {
  anonymous(): void;
  attempt(username: string, password: string, option?: SelectCompany): void;
  reset(): void;

  loading: boolean;
  error: FetchMediaError | Error | null;
  options: SelectCompany[];
}

export interface AuthenticateEmail {
  attempt: UseMutateAsyncFunction<
    AuthenticateResult,
    Error | FetchMediaError,
    AuthenticateArgs,
    unknown
  >;

  reset(): void;

  isLoading: boolean;
  error: FetchMediaError | Error | null;
}

type AuthenticateArgs = {
  username: string;
  password: string;
  option?: SelectCompany;
};

export interface Validate {
  validate: UseMutateAsyncFunction<
    SelectCompany[],
    Error | FetchMediaError,
    string,
    unknown
  >;

  reset(): void;

  options: SelectCompany[] | undefined;
  isLoading: boolean;
  error: FetchMediaError | Error | null;
}

export function useAuthentication() {
  return useContext(AuthenticationContext).authentication;
}

export function useAuthenticationSet() {
  return useContext(AuthenticationContext).set;
}

const AUTHENTICATION_LISTENERS: ((
  next: AnyValue<Permit | null>
) => void)[] = [];

function useProvideAuthentication(): {
  authentication: AnyValue<Permit | null>;
  set: (next: AnyValue<Permit | null>) => Promise<void>;
} {
  const current = useMemoryValue(AUTHENTICATION);
  const lastSet = useRef(current);

  const set = useCallback((next: AnyValue<Permit | null>) => {
    lastSet.current = next;

    return AUTHENTICATION.emit(next, true, false).then((next) =>
      AUTHENTICATION_LISTENERS.forEach((listener) => listener(next))
    );
  }, []);

  if (current !== lastSet.current) {
    set(current);
  }

  return useMemo(() => ({ authentication: current, set }), [current, set]);
}

export function AuthenticationProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const value = useProvideAuthentication();
  return createElement(AuthenticationContext.Provider, { value }, children);
}

type AuthenticateResult = {
  company?: TactileCompany;
  nextDomain: string;
  expiresAt: number;
  token: string;
};

type ValidatedResponse = {
  message: string;
  data: {
    company: {
      correct: false;
      select: SelectCompany[];
    };
  };
};

export function useValidateEmail(): Validate {
  const abortable = useAbortController();

  const { mutateAsync, error, isLoading, data, reset } = useMutation<
    SelectCompany[],
    FetchMediaError | Error,
    string
  >(['auth', 'user', 'validate'], (username: string) => {
    if (!username) {
      throw new Error('Enter your email address');
    }

    const body = {
      email: username,
    };

    const endpoint = BASE_ENDPOINT;
    const url = [endpoint, 'api', 'public', 'auth', 'email-validate'].join('/');

    const ac = abortable();

    async function fetch() {
      const response = await fetchMedia(url, {
        headers: {
          accept: ACCEPT,
          contentType: CONTENT_TYPE,
        },
        method: 'POST',
        body,
        disableFormData: true,
        disableFormUrlEncoded: true,
        disableText: true,
        signal: ac.signal,
      })
        .catch((error) => {
          if (error instanceof FetchMediaError) {
            if (error.response.status === 300) {
              return (error as JsonError).data;
            }
          }

          return Promise.reject(error);
        })
        .then((response) => response as ValidatedResponse);
      return response.data?.company?.select || [];
    }

    const cancellable = fetch();

    // This is a non-standard property on a promise, so the error here needs to
    // be ignored. However, react-query will check this non-standard property
    // and use it if it's available.
    //
    // @ts-ignore
    cancellable.cancel = () => {
      ac && ac.abort();
    };

    return cancellable;
  });

  return { validate: mutateAsync, error, isLoading, options: data, reset };
}

export function useAuthenticateEmail(): AuthenticateEmail {
  const [, setCompany] = useMutableCompany();
  const authenticateWith = useAuthenticationSet();
  const abortable = useAbortController();

  const { mutateAsync, error, isLoading, reset } = useMutation<
    AuthenticateResult,
    FetchMediaError | Error,
    AuthenticateArgs
  >(
    ['auth', 'user', 'email'],
    ({ username, password, option }) => {
      if (!username) {
        throw new Error('Enter your email address');
      }

      if (!password) {
        throw new Error('Enter your password');
      }

      const ac = abortable();

      const endpoint =
        option && option.domainFull ? option.domainFull : BASE_ENDPOINT;
      const url = [endpoint, 'api', 'public', 'auth', 'login'].join('/');

      const body: PermitRequest = {
        email: username,
        password,
      };

      async function call() {
        const { data, message } = (await fetchMedia(url, {
          headers: {
            accept: ACCEPT,
            contentType: CONTENT_TYPE,
          },
          method: 'POST',
          body,
          disableFormData: true,
          disableFormUrlEncoded: true,
          disableText: true,
          signal: ac.signal,
        })) as any;

        const hasValidUser = dlv(data, ['email', 'correct'], false);

        if (!hasValidUser || dlv(data, ['password', 'correct']) === false) {
          throw new Error('E-mail or password incorrect');
        }

        const {
          token: { value: token, expires: expiresAt },
        } = data;

        // Need this guard because the API will return a success code even when it's not ok.
        if (!token) {
          throw new Error(
            message || 'Something went wrong; please contact us or try again.'
          );
        }

        Analytics.logEvent('login', { method: 'email' });

        const nextDomain = option?.domainFull || BASE_ENDPOINT;

        const company = MULTI_COMPANY_ENABLED
          ? await fetchApplication(nextDomain + '/api', ac.signal, __DEV__)
          : undefined;

        return { company, nextDomain, token, expiresAt };
      }

      const cancellable = call();

      return cancellable;
    },
    {
      onSuccess: ({ company, nextDomain, token, expiresAt }) => {
        company && setCompany(company);
        authenticateWith({
          domainFull: nextDomain,
          token,
          expiresAt,
        });
      },
    }
  );

  return { attempt: mutateAsync, error, isLoading, reset };
}

export function useLogout() {
  const authenticateWith = useAuthenticationSet();
  return useCallback(() => authenticateWith(null), [authenticateWith]);
}

export function imperativeLogout() {
  return AUTHENTICATION.emit(null, true, false);
}

export function useEndpoint() {
  const authenticatedDomain = useAuthentication()?.domainFull;
  return `${
    MULTI_COMPANY_ENABLED ? authenticatedDomain || BASE_ENDPOINT : BASE_ENDPOINT
  }/api`;
}

export function useIsAuthenticated(): boolean | UndeterminedValue {
  const authentication = useAuthentication();

  if (authentication === undefined) {
    return undefined;
  }

  return !!authentication;
}

export function useIsAnonymous(): boolean | UndeterminedValue {
  const authentication = useAuthentication();
  if (authentication === undefined) {
    return undefined;
  }

  return !authentication || authentication.token === '__fake';
}

export function useAuthorization(): string {
  return assert(useSafeAuthorization());
}

export function useSafeAuthorization(): string | null | undefined {
  const authentication = useAuthentication();
  return authentication ? `${authentication.token || ''}` : authentication; // Token token=${authentication.token}
}

export function runOnLogout(listener: () => void) {
  const onLogout = (next: AnyValue<Permit | null>) => {
    if (next === null) {
      listener();
    }
  };

  AUTHENTICATION_LISTENERS.push(onLogout);

  return function unsubscribe() {
    const index = AUTHENTICATION_LISTENERS.indexOf(onLogout);
    if (index !== -1) {
      AUTHENTICATION_LISTENERS.splice(index, 1);
    }
  };
}

if (typeof window !== undefined && 'addEventListener' in window) {
  const key = 'token';

  const onSettingsMessage = (ev: MessageEvent) => {
    if (typeof ev.data !== 'object' || ev.data === null) {
      return;
    }

    if (ev.data.type !== key) {
      return;
    }

    const {
      token,
      domain = BASE_ENDPOINT,
      expires = new Date().getTime() + 1000 * 60 * 60,
    } =
      typeof ev.data.value === 'string'
        ? { token: ev.data.value }
        : ev.data.value;

    if (MULTI_COMPANY_ENABLED) {
      // Allow other domains
      fetchApplication(domain + '/api', undefined, __DEV__).then((company) => {
        imperativeLogout().then(() => {
          COMPANY.emit(company);
          AUTHENTICATION.emit({
            // one hour
            expiresAt: expires,
            token,
            domainFull: domain,
          });
        });
      });
    } else {
      // Force the correct domain
      fetchApplication(BASE_ENDPOINT + '/api', undefined, __DEV__).then(
        (company) => {
          imperativeLogout().then(() => {
            COMPANY.emit(company);
            AUTHENTICATION.emit({
              // one hour
              expiresAt: expires,
              token,
              domainFull: BASE_ENDPOINT,
            });
          });
        }
      );
    }
  };

  window.addEventListener('message', onSettingsMessage);
}
