import { TactileNewsItem } from '@introcloud/api-client';
import { Spacer, TextBlock, useBlockNavigation } from '@introcloud/blocks';
import React, { useCallback, useMemo } from 'react';
import { View } from 'react-native';
import { Card, List, useTheme } from 'react-native-paper';
import {
  localeLongWeekDayString,
  localeTimeString,
  localeDateString,
} from 'react-native-time-helpers';
import { useMediaQuery } from 'react-responsive';

import { LinkButton } from '../core/LinkButton';
import { useNews } from '../hooks/useNews';

export function LatestNewsItem() {
  const { data: news } = useNews();
  const otherNews = news?.filter((item) => item.kind !== 'pinned') || [];

  if (otherNews.length === 0) {
    return null;
  }

  const [newsItem] = otherNews;

  return (
    <View style={{ width: '100%', position: 'relative' }}>
      <List.Subheader>Latest news</List.Subheader>
      <LastNewsItem item={newsItem} />
      <Spacer space={1} />

      <LinkButton to="/news" style={{ position: 'absolute', right: 4, top: 4 }}>
        See older
      </LinkButton>
    </View>
  );
}

export function LastNewsItem({ item }: { item: TactileNewsItem }) {
  const { gotoInfo } = useBlockNavigation();
  const onPress = useCallback(
    () => gotoInfo(item.pageRef.pageId || (item.pageRef as any)?.page?._id),
    [gotoInfo, item.pageRef]
  );
  const date = useMemo(() => new Date(item.meta.publish.at.unix), [item]);
  const mobileView = useMediaQuery({ query: '(max-width: 736px)' }); // 720 + 16
  const theme = useTheme();
  const finalTheme = useMemo(
    () => ({ ...theme, roundness: mobileView ? 0 : theme.roundness }),
    [theme, mobileView]
  );

  return (
    <Card theme={finalTheme} elevation={1} onPress={onPress}>
      <Card.Title
        title={item.name.full}
        subtitle={`${localeDateString(date, true, false)}, ${localeTimeString(
          date
        )}`}
      />
      <TextBlock text={item.name.description} round={{ bottom: !mobileView }} />
    </Card>
  );
}
