import { fetchApplicationEventGoalRanking } from '@introcloud/api-client';
import { LinearGradient } from 'expo-linear-gradient';
import React, { Fragment, memo, useCallback, useMemo } from 'react';
import { Image, ScrollView, StyleSheet, View } from 'react-native';
import { Card, Headline, Text, useTheme } from 'react-native-paper';
import { useQuery } from 'react-query';
import { AA_THRESHOLD_CONTRAST, useContrast } from 'use-color-luminance';

import { NotReady } from '../core/errors/NotReady';
import { useAbortController } from '../hooks/useAbortController';
import { useAuthorization, useEndpoint } from '../hooks/useAuthentication';
import { useGroupsDetached } from '../hooks/useGroup';

function Ranking_({ eventId }: { eventId: string }) {
  // const [waitingForAnimation, setWaitingForAnimation] = useState(false);
  const endpoint = useEndpoint();
  const authorization = useAuthorization();
  const abortable = useAbortController();
  const { groups } = useGroupsDetached();

  const fetcher = useCallback(() => {
    if (!authorization || !endpoint) {
      throw new NotReady();
    }

    const ac = abortable();

    async function call() {
      return await fetchApplicationEventGoalRanking(
        eventId,
        endpoint,
        authorization,
        ac.signal,
        __DEV__
      );
    }

    const cancellable = call();

    // This is a non-standard property on a promise, so the error here needs to
    // be ignored. However, react-query will check this non-standard property
    // and use it if it's available.
    //
    // @ts-ignore
    cancellable.cancel = () => {
      ac && ac.abort();
    };

    return cancellable;
  }, [endpoint, authorization, abortable]);

  const { data: ranking, error } = useQuery([eventId, authorization], fetcher, {
    enabled: !!(authorization && endpoint && eventId),
  });

  const finalRanking = useMemo(() => {
    if (!ranking || !groups || groups.length === 0 || ranking.length === 0) {
      return [];
    }

    const sorted = ranking
      .sort((a, b) => b.points - a.points)
      .map((item, index) => ({ ...item, placement: index + 1 }));
    const selfIndex = sorted.findIndex((ranked) =>
      groups.some((group) => group._id === ranked._id)
    );

    if (selfIndex === -1) {
      return sorted.slice(0, 6).map((item) => ({ ...item, isSelf: false }));
    }

    if (selfIndex < 6) {
      return sorted
        .slice(0, 6)
        .map((item, index) => ({ ...item, isSelf: index === selfIndex }));
    }

    const self = sorted[selfIndex];

    return sorted
      .slice(0, 3)
      .concat(sorted.slice(selfIndex - 1, selfIndex + 2))
      .map((item) => ({ ...item, isSelf: self._id === item._id }));
  }, [ranking, groups]);

  const [first, ...rest] = finalRanking;
  const {
    dark,
    colors: { primary, accent, background },
  } = useTheme();

  const primaryHasContrast =
    useContrast(primary, dark ? '#121212' : '#fff') > AA_THRESHOLD_CONTRAST;
  const accentHasContrast =
    useContrast(accent, dark ? '#121212' : '#fff') > AA_THRESHOLD_CONTRAST;
  const fallback = dark ? '#fff' : '#121212';
  const color = primaryHasContrast
    ? primary
    : accentHasContrast
    ? accent
    : fallback;

  if (!first) {
    return null;
  }
  return (
    <View
      style={{
        width: '100%',
        overflow: 'hidden',
        paddingTop: 16,
        backgroundColor: background,
      }}
    >
      <ScrollView
        nativeID="custom-scroller"
        horizontal
        contentContainerStyle={{
          alignSelf: 'center',
          justifyContent: 'center',
          marginHorizontal: 'auto',
        }}
      >
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            paddingHorizontal: 16,
            paddingBottom: 16,
          }}
        >
          {first ? (
            <Card
              style={{
                elevation: 1,
                marginRight: 8,
                width: 240,
                height: 96,
                overflow: 'hidden',
              }}
            >
              <Image
                style={{ borderRadius: 4, width: 240, height: 96 }}
                source={{
                  width: 500,
                  height: 500,

                  uri:
                    'https://images.unsplash.com/photo-1513151233558-d860c5398176?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=480&q=80',
                }}
                resizeMode="cover"
              />

              <LinearGradient
                style={[StyleSheet.absoluteFill, { borderRadius: 4 }]}
                colors={[
                  'rgba(255, 255, 255, 0.7)',
                  'rgba(255, 255, 255, 0.3)',
                ]}
              />

              <Card.Content
                style={{
                  ...StyleSheet.absoluteFillObject,
                  padding: 16,
                  borderBottomWidth: 2,
                  borderBottomColor: first.isSelf ? color : 'transparent',
                }}
              >
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Text
                    style={{
                      fontSize: 48,
                      marginRight: 16,
                    }}
                  >
                    1
                  </Text>

                  <View
                    style={{
                      borderLeftWidth: StyleSheet.hairlineWidth,
                      borderLeftColor: 'rgba(0, 0,0, .2)',
                      paddingLeft: 16,
                      height: 64,
                      justifyContent: 'center',
                    }}
                  >
                    <Text>{first.name.full}</Text>
                    <Headline style={{ fontSize: 21 }}>
                      {first.points} pts
                    </Headline>
                  </View>
                </View>
              </Card.Content>
            </Card>
          ) : null}
          {(rest || []).map((rank, index, self) => (
            <Fragment key={rank._id}>
              {!self[index - 1] ||
              self[index - 1].placement === rank.placement - 1 ? null : (
                <View style={{ width: 64 }} key={rank._id + '- spacer'} />
              )}
              <Card
                style={{
                  elevation: 1,
                  marginRight: 8,
                  width: 240,
                  height: 96,
                  overflow: 'hidden',
                }}
                key={rank._id}
              >
                <Card.Content
                  style={{
                    ...StyleSheet.absoluteFillObject,
                    padding: 16,
                    borderBottomWidth: 2,
                    borderBottomColor: rank.isSelf ? color : 'transparent',
                  }}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 32,
                        marginRight: 16,
                      }}
                    >
                      {rank.placement}
                    </Text>

                    <View
                      style={{
                        borderLeftWidth: StyleSheet.hairlineWidth,
                        borderLeftColor: 'rgba(0, 0,0, .2)',
                        paddingLeft: 16,
                        height: 64,
                        justifyContent: 'center',
                      }}
                    >
                      <Text>{rank.name.full}</Text>
                      <Headline style={{ fontSize: 21 }}>
                        {rank.points} pts
                      </Headline>
                    </View>
                  </View>
                </Card.Content>
              </Card>
            </Fragment>
          ))}
        </View>
      </ScrollView>
    </View>
  );
}

export const Ranking = memo(Ranking_);
