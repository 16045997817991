import {
  submitUserImage,
  TactileGroup,
  TactileGroupUser,
} from '@introcloud/api-client';
import {
  AccentButton,
  PrimaryButton,
  Spacer,
  TextButton,
  useBlockData,
  useBlockNavigation,
} from '@introcloud/blocks';
import { useNavigation } from '@react-navigation/native';
import Constants from 'expo-constants';
import React, { Fragment, memo, useCallback, useMemo, useState } from 'react';
import {
  Clipboard,
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import {
  Appbar,
  Avatar,
  Caption,
  Card,
  DataTable,
  Dialog,
  IconButton,
  List,
  Paragraph,
  Portal,
  Surface,
  Title,
  useTheme,
} from 'react-native-paper';
import { useHasContrastOnLight } from 'use-color-luminance';
import { useIsMounted } from 'use-is-mounted';
import { BlockProvision } from '../core/BlockProvision';
import { Header } from '../core/Header';
import {
  BLOCK_CUSTOM_EMBED,
  BLOCK_GELEGRAAF,
  BLOCK_INFO_MARKET,
  BLOCK_RIJKSMUSEUM,
  BLOCK_ZOO_AUDIO_TOUR,
  CHAT_BUTTONS_ENABLED,
  EXPERIENCE_ENABLED,
  GELEGRAAF_ENABLED,
  INITIAL_MAP_FILTER,
  MAP_SWIPER_ENABLED,
  PAGE_COLORS_ENABLED,
  TAG_OVERRIDES_ENABLED,
} from '../features';
import { FileDialogContent } from '../goals/FileDialogContent';
import {
  useAuthentication,
  useEndpoint,
  useLogout,
  useSafeAuthorization,
} from '../hooks/useAuthentication';
import { useGroups } from '../hooks/useGroup';
import { useTab } from '../hooks/useTab';
import { useUser } from '../hooks/useUser';
import { ContactMenu } from './ContactMenu';

export function ProfileScreen({ asTab }: { asTab?: boolean }) {
  const [debugDialogActive, setDebugDialogActive] = useState(false);
  const showDebugDialog = useCallback(() => setDebugDialogActive(true), []);
  const hideDebugDialog = useCallback(() => setDebugDialogActive(false), []);

  return (
    <BlockProvision screen="ProfileScreen">
      <View
        style={{
          flex: 1,
          overflow: 'hidden',
          maxHeight: Platform.select({ web: '100vh', default: '100%' }),
        }}
      >
        <Header
          backFallback={{ screen: 'Tabs', params: { screen: 'Home' } }}
          hideProfile
          hideBack={asTab}
          title="Profile"
          subTitle={undefined}
        >
          <Appbar.Action
            icon="bug"
            accessibilityLabel="See debug information"
            onPress={() => showDebugDialog()}
          />
        </Header>
        <ScrollView
          nativeID="scroller"
          style={{ flex: 1, maxHeight: '100%' }}
          contentContainerStyle={{
            maxWidth: 720,
            alignSelf: 'center',
            paddingBottom: 56,
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <View>
            <UserCard />
            {CHAT_BUTTONS_ENABLED ? <ChatButton /> : null}
            <GroupList />
            <Logout />
          </View>
        </ScrollView>
      </View>
      <Portal>
        <Dialog
          visible={debugDialogActive}
          onDismiss={hideDebugDialog}
          style={{ alignSelf: 'center', maxWidth: 450, width: '100%' }}
        >
          <DebugDialogContent onDismiss={hideDebugDialog} />
        </Dialog>
      </Portal>
    </BlockProvision>
  );
}

function DebugDialogContent({ onDismiss }: { onDismiss: () => void }) {
  const { data: user } = useUser();
  const authenticatedDomain = useAuthentication()?.domainFull;
  const domain = useEndpoint();
  const [copied, setCopied] = useState(false);

  const information = useMemo((): [string, string][] => {
    return [
      ['Device', Constants.isDevice ? 'Yes' : 'No'],
      ['Device name', Constants.deviceName || 'Unknown'],
      ['Device class', Constants.deviceYearClass || '-'],
      ['Runtime version', Constants.expoRuntimeVersion || '-'],
      ['App version', Constants.nativeAppVersion || '-'],
      ['App build', Constants.nativeBuildVersion || '-'],
      ['App owner', Constants.appOwnership || '-'],
      ['Platform', Platform.OS || '-'],
      [
        'Platform model',
        Platform.select({ ios: Constants.platform?.ios?.model }) || '-',
      ],
      ['Platform version', Platform.Version || '-'],
      ['System version', Constants.systemVersion || '-'],
      ['', ''],
      ['Manifest name', Constants.manifest.name || '-'],
      ['Release channel', Constants.manifest.releaseChannel || 'none'],
      ['Release id', Constants.manifest.releaseId || '-'],
      ['Release revision', Constants.manifest.revisionId || '-'],
      ['Release version', Constants.manifest.version || '-'],
      ['Release sdk', Constants.manifest.sdkVersion || '-'],
      ['', ''],
      ['IntroCloud locale', Constants.manifest.extra['locale'] || '-'],
      [
        'IntroCloud domain',
        Constants.manifest.extra['whitelabel-domain'] ||
          Constants.manifest.extra['introcloud-domain'] ||
          'none',
      ],
      ['Initial map filter', INITIAL_MAP_FILTER || '<none>'],
      ['Custom Newspaper', GELEGRAAF_ENABLED ? 'yes' : 'no'],
      ['Custom Map', MAP_SWIPER_ENABLED ? 'yes' : 'no'],
      ['Custom Page colors', PAGE_COLORS_ENABLED ? 'yes' : 'no'],
      ['Custom Tag names', TAG_OVERRIDES_ENABLED ? 'yes' : 'no'],
      ['Custom Experience', EXPERIENCE_ENABLED ? 'yes' : 'no'],
      ['Extra Chat buttons', CHAT_BUTTONS_ENABLED ? 'yes' : 'no'],
      ['Block newspaper', BLOCK_GELEGRAAF ? 'yes' : 'no'],
      ['Block info market', BLOCK_INFO_MARKET ? 'yes' : 'no'],
      ['Block audio tour', BLOCK_ZOO_AUDIO_TOUR ? 'yes' : 'no'],
      ['Block custom embed', BLOCK_CUSTOM_EMBED ? 'yes' : 'no'],
      ['Block rijksmuseum', BLOCK_RIJKSMUSEUM ? 'yes' : 'no'],
      ['', ''],
      ['Authenticated user', user?.email?.value || '-'],
      [
        'Authenticated domain',
        authenticatedDomain?.replace('https://', '') || '-',
      ],
      [
        'Active domain',
        domain?.replace('https://', '')?.replace('/api', '') || '-',
      ],
    ];
  }, [user, authenticatedDomain, domain]);

  const copyToClipboard = useCallback(() => {
    const leftSize = Math.max(...information.map((row) => row[0]?.length || 0));

    Clipboard.setString(
      information
        .map((row) =>
          row[0] === '' ? '' : `${row[0].padEnd(leftSize, ' ')}\t\t${row[1]}`
        )
        .join('\n')
    );

    setCopied(true);
  }, [information]);
  return (
    <Fragment>
      <Dialog.Title>Device and App information</Dialog.Title>
      <Dialog.Content>
        <Paragraph>
          In order to resolve any issues encountered, there is information that
          will really help us track down the root cause. Please copy this
          information and provide it when you report any issues.
        </Paragraph>
      </Dialog.Content>
      <Dialog.ScrollArea style={{ paddingHorizontal: 0 }}>
        <ScrollView style={{ maxHeight: 300 }}>
          <DataTable>
            {information.map((row, index, self) => (
              <DataTable.Row key={index}>
                {row.map((cell, i) => (
                  <DataTable.Cell
                    numeric={i > 0}
                    key={i}
                    style={{
                      flexShrink: i === 1 ? 0 : 1,
                      paddingHorizontal: 10,
                    }}
                  >
                    {cell}
                  </DataTable.Cell>
                ))}
              </DataTable.Row>
            ))}
          </DataTable>
        </ScrollView>
      </Dialog.ScrollArea>
      <Dialog.Actions
        style={{ paddingHorizontal: 16, paddingBottom: 12, paddingTop: 12 }}
      >
        <PrimaryButton
          icon={copied ? 'check' : 'content-copy'}
          onPress={copyToClipboard}
          style={{ marginRight: 'auto' }}
        >
          {copied ? 'Copied' : 'Copy to clipboard'}
        </PrimaryButton>
        <TextButton icon="close" onPress={onDismiss}>
          Close
        </TextButton>
      </Dialog.Actions>
    </Fragment>
  );
}

function UserCard() {
  const endpoint = useEndpoint();
  const authorization = useSafeAuthorization();
  const { data: user, error, loading, reload } = useUser();
  const theme = useTheme();
  const { getImageUrl } = useBlockData();

  const isMountedRef = useIsMounted();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [lastError, setLastError] = useState<null | Error>(null);
  const [dialogVisible, setDialogVisible] = useState(false);

  const submit = useCallback(
    (data: FormData) => {
      if (!user?._id || !authorization) {
        return;
      }

      setIsSubmitting(true);
      setLastError(null);

      if (typeof data.has === 'undefined') {
        data.has = (key: string) => key === 'filepond';
      }

      submitUserImage(
        user?._id,
        data,
        endpoint,
        authorization,
        undefined,
        __DEV__
      )
        .then(() => {
          if (!isMountedRef.current) {
            return;
          }

          setIsSubmitting(false);
          setDialogVisible(false);
          reload();
        })
        .catch((err: Error) => {
          console.error(err);
          if (!isMountedRef.current) {
            return;
          }

          setIsSubmitting(false);
          setLastError(err);
        });
    },
    [user, endpoint, authorization]
  );

  return (
    <Fragment>
      <Card.Content style={{ marginVertical: 16 }}>
        <View style={{ flexDirection: 'row' }}>
          <Card
            style={{ width: 96, height: 96 }}
            onPress={() => setDialogVisible(true)}
          >
            <Image
              source={{
                uri:
                  getImageUrl(user?.image.profile || '', 'icon_256') ||
                  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
                width: 256,
                height: 256,
              }}
              style={{
                width: 96,
                height: 96,
                borderRadius: theme.roundness,
              }}
              resizeMode="cover"
            />
          </Card>

          <View style={{ marginLeft: 16 }}>
            <Title>{user?.name?.first || user?.name?.full}</Title>
            <Caption>{user?.email?.value}</Caption>
            <AccentButton
              icon="camera"
              onPress={() => setDialogVisible(true)}
              compact
              style={{ marginTop: 3 }}
              labelStyle={{
                includeFontPadding: false,
                textAlignVertical: 'center',
              }}
            >
              Change image
            </AccentButton>
          </View>
        </View>
      </Card.Content>
      <Portal>
        <Dialog
          visible={dialogVisible}
          onDismiss={() => {
            setDialogVisible(false);
          }}
          style={{
            maxWidth: 720,
            alignSelf: 'center',
            minWidth: 300,
            overflow: 'hidden',
          }}
        >
          <Dialog.Content>
            <FileDialogContent
              formDataKey="filepond"
              kind="image"
              loading={isSubmitting || loading}
              onDismiss={() => {
                setDialogVisible(false);
              }}
              onSubmit={(data: string | File | Blob | FormData) => {
                submit(data as FormData);
              }}
              error={lastError}
            />
            <Dialog.Actions style={{ padding: 0, marginTop: 16 }}>
              <Caption>
                It can take a while for your new image to show up everywhere.
              </Caption>
            </Dialog.Actions>
          </Dialog.Content>
        </Dialog>
      </Portal>
    </Fragment>
  );
}

function ChatButton() {
  const { navigate } = useNavigation();
  const tab = useTab('chats');

  return (
    <PrimaryButton
      style={{
        marginTop: 8,
        marginRight: 'auto',
        marginLeft: 16,
        marginBottom: 8,
      }}
      icon={
        ((!tab?.icon.family || tab?.icon.family === 'MaterialCommunityIcons') &&
          (tab?.icon.name as 'forum')) ||
        'chat'
      }
      onPress={() => navigate('Chats')}
    >
      Your chats
    </PrimaryButton>
  );
}

function GroupList() {
  const { groups } = useGroups();

  if (groups === undefined) {
    return null;
  }

  if (groups === null || groups.length === 0) {
    return <NoGroups />;
  }

  return (
    <Fragment>
      {groups.map((group, index) => {
        return <Group key={group.name.id + '-' + index} {...group} />;
      })}
    </Fragment>
  );
}

function NoGroups() {
  return (
    <Fragment>
      <List.Subheader>Your group</List.Subheader>
      <Paragraph style={{ marginHorizontal: 16 }}>
        Once you've been assigned to a group, everyone in it will appear here.
        Check back later!
      </Paragraph>
    </Fragment>
  );
}

function GroupUser_(user: TactileGroupUser & { hideChat?: boolean }) {
  const { data: self } = useUser();

  const { getImageUrl } = useBlockData();
  const url = getImageUrl(user.image.profile || '', 'icon_128');

  const {
    mobile: { countrycode, value, valid },
    hideChat,
  } = user;

  const phone =
    valid && (value?.length || 0 > 5) ? `${countrycode || ''}${value}` : '';

  const initials = [user.name.first?.[0], user.name.last?.[0]]
    .concat(user.name.full?.split(' '))
    .slice(0, 2)
    .map((l) => (l || '')[0])
    .join('');

  const {
    colors: { primary },
  } = useTheme();
  const textColor = useHasContrastOnLight(primary) ? '#fff' : '#121212';

  const [active, setActive] = useState(false);
  const toggleMenu = useCallback(() => setActive((prev) => !prev), [setActive]);
  const renderIllustration = useCallback(
    () =>
      url ? (
        <Avatar.Image
          size={40}
          style={{
            marginLeft: 8,
            marginRight: 16,
            marginTop: 4,
            marginBottom: 4,
          }}
          source={{ uri: url, width: 40, height: 40 }}
        />
      ) : (
        <Avatar.Text
          color={textColor}
          style={{
            marginLeft: 8,
            marginRight: 16,
            marginTop: 4,
            marginBottom: 4,
          }}
          size={40}
          label={initials}
        />
      ),
    [url, initials]
  );

  const renderRight = useCallback(
    (props: { color: string }) => {
      if (self?._id === user._id) {
        return null;
      }

      return (
        <View style={{ flexDirection: 'row' }}>
          <Fragment>
            <ContactMenu
              id={user._id}
              active={active}
              onDismiss={toggleMenu}
              anchor={
                <IconButton
                  size={24}
                  icon="card-account-details"
                  onPress={toggleMenu}
                />
              }
              name={user.name.first || user.name.full?.split(' ').shift() || ''}
              phone={phone}
            />
          </Fragment>
        </View>
      );
    },
    [phone, user.name.first, user.name.full, active, toggleMenu]
  );

  return (
    <List.Item
      left={renderIllustration}
      style={styles.item}
      onPress={toggleMenu}
      title={user.name.full}
      description={user.role.id === 'leader' ? 'Mentor' : ''}
      right={renderRight}
      disabled={self?._id === user._id}
    />
  );
}

export const GroupUser = memo(GroupUser_);

function Group(group: TactileGroup) {
  const theme = useTheme();
  const { gotoChat } = useBlockNavigation();

  return (
    <View style={{ width: '100%', position: 'relative' }}>
      <List.Subheader>{group.name.full}</List.Subheader>
      <Surface
        style={{
          elevation: 1,
          borderRadius: theme.roundness,
          marginHorizontal: 16,
        }}
      >
        {group.users
          .filter((user) => user.role.id === 'leader')
          .sort((a, b) => (a.name.full || '').localeCompare(b.name.full || ''))
          .map((user) => (
            <GroupUser {...user} key={user._id} />
          ))}
      </Surface>
      <Spacer space={1} />
      <Surface
        style={{
          elevation: 1,
          borderRadius: theme.roundness,
          marginHorizontal: 16,
        }}
      >
        {group.users
          .filter((user) => user.role.id !== 'leader')
          .sort((a, b) => (a.name.full || '').localeCompare(b.name.full || ''))
          .map((user) => (
            <GroupUser {...user} key={user._id} />
          ))}
      </Surface>
      <Spacer space={2} />
      <TextButton
        icon="forum"
        style={{ position: 'absolute', right: 16, top: 4 }}
        onPress={() =>
          gotoChat(`:group/${JSON.stringify({ group: group._id })}`)
        }
      >
        Chat
      </TextButton>
    </View>
  );
}

function Logout() {
  const logout = useLogout();

  return (
    <PrimaryButton
      onPress={logout}
      style={{ marginHorizontal: 16, marginVertical: 16, alignSelf: 'center' }}
    >
      Logout
    </PrimaryButton>
  );
}

const styles = StyleSheet.create({
  item: {
    justifyContent: 'space-between',
    width: '100%',
  },
});
