import { PrimaryButton, TextButton } from '@introcloud/blocks';
import React, { Fragment, useState } from 'react';
import { View } from 'react-native';
import { TextInput } from 'react-native-paper';

import { DialogContentProps } from './types';

export function TextDialogContent({
  multiline,
  loading,
  onDismiss,
  onSubmit,
}: {
  multiline: boolean;
} & DialogContentProps) {
  const [text, setText] = useState('');
  return (
    <View style={{ maxHeight: 248 }}>
      <TextInput
        multiline={multiline}
        label="Answer"
        style={multiline ? { height: 200 } : undefined}
        value={text}
        onChangeText={setText}
        returnKeyType="send"
        blurOnSubmit
        onSubmitEditing={() => onSubmit(text)}
      />
      <View style={{ flexDirection: 'row' }}>
        <PrimaryButton
          icon="send"
          onPress={() => onSubmit(text)}
          disabled={loading || !text}
          loading={loading}
          style={{ marginRight: 'auto', marginTop: 16 }}
        >
          Submit
        </PrimaryButton>
        <TextButton
          onPress={onDismiss}
          style={{ marginLeft: 8, marginTop: 16 }}
        >
          Close
        </TextButton>
      </View>
    </View>
  );
}
