import {
  TactileEvent,
  TactileLocation,
  TactilePage,
  TactilePagePreview,
  TactileVideoIndex,
} from '@introcloud/api-client';
import { createRef, MutableRefObject } from 'react';

import { runOnLogout } from '../hooks/useAuthentication';

export interface ICacheOptions {
  policy: ICachePolicy;
}

export interface ICachePolicy {
  maxEntries: number;
}

class LRUMemoryCache<V> {
  protected policy: ICachePolicy;
  protected memoryCache: { current: Record<string, V> | null };
  protected accessList: string[];

  constructor(options: ICacheOptions) {
    this.policy = options.policy;
    this.memoryCache = createRef();
    this.memoryCache.current = {};
    this.accessList = [];
  }

  public clearAll() {
    this.memoryCache.current = {};

    return this.setLRU([]);
  }

  protected enforceLimits() {
    if (!this.policy.maxEntries) {
      return;
    }

    const lru = this.accessList;
    const victimCount = Math.max(0, lru.length - this.policy.maxEntries);
    const victimList = lru.slice(0, victimCount);

    for (const victimKey of victimList) {
      this.remove(victimKey);
    }

    const survivorList = lru.slice(victimCount);
    this.setLRU(survivorList);
  }

  public getAll(): Readonly<Record<string, V>> {
    return this.memoryCache.current!;
  }

  public get(key: string): V | undefined {
    const value = this.peek(key);

    if (!value) {
      return undefined;
    }

    this.refreshLRU(key);

    return value;
  }

  public peek(key: string): V | undefined {
    return this.memoryCache.current![key];
  }

  public remove(key: string) {
    delete this.memoryCache.current![key];
    return this.removeFromLRU(key);
  }

  public set(key: string, value: V) {
    this.memoryCache.current![key] = value;
    this.refreshLRU(key);
    return this.enforceLimits();
  }

  protected async addToLRU(key: string) {
    this.accessList.push(key);
    return this.setLRU(this.accessList);
  }

  protected refreshLRU(key: string) {
    this.removeFromLRU(key);
    return this.addToLRU(key);
  }

  protected removeFromLRU(key: string) {
    const lru = this.accessList;

    const newLRU = lru.filter((item: string) => {
      return item !== key;
    });

    return this.setLRU(newLRU);
  }

  protected setLRU(lru: string[]) {
    this.accessList = lru;
  }
}

const EventCacheRef: MutableRefObject<Record<
  string,
  TactileEvent
> | null> = createRef();
EventCacheRef.current = {};

const LocationCacheRef: MutableRefObject<Record<
  string,
  TactileLocation
> | null> = createRef();
LocationCacheRef.current = {};

const VideoCacheRef: MutableRefObject<Record<
  string,
  TactileVideoIndex[number]
> | null> = createRef();
VideoCacheRef.current = {};

export const PAGE_CACHE = new LRUMemoryCache<TactilePage | TactilePagePreview>({
  policy: { maxEntries: __DEV__ ? 3 : 50 },
});

export const EVENT_CACHE = EventCacheRef as Readonly<{
  current: Record<string, TactileEvent>;
}>;
export const LOCATION_CACHE = LocationCacheRef as Readonly<{
  current: Record<string, TactileLocation>;
}>;
export const VIDEO_CACHE = VideoCacheRef as Readonly<{
  current: Record<string, TactileVideoIndex[number]>;
}>;

runOnLogout(() => {
  Object.keys(EventCacheRef.current!).forEach((key) => {
    delete EventCacheRef.current![key];
  });

  Object.keys(LocationCacheRef.current!).forEach((key) => {
    delete LocationCacheRef.current![key];
  });

  Object.keys(VideoCacheRef.current!).forEach((key) => {
    delete VideoCacheRef.current![key];
  });

  PAGE_CACHE.clearAll();
});
