import { usePubNub } from 'pubnub-react';
import React, { Fragment, useCallback } from 'react';
import { Card, Text } from 'react-native-paper';
import Animated, {
  add,
  cond,
  diff,
  divide,
  eq,
  greaterThan,
  max,
  min,
  multiply,
  set,
  startClock,
  useCode,
} from 'react-native-reanimated';
import { useClock, useValues } from 'react-native-redash';
import { useTimestamp } from 'react-native-use-timestamp';
import { useChatImage } from '../chats/useChatImage';
import { useChatInitials } from '../chats/useChatInitials';
import { useChatUserInfo } from '../chats/useChatUserInfo';
import { PlayerRef, useGameMapInteraction } from './GameMapInteraction';
import { useViewport } from './ViewPort';

function Players_() {
  const { displayFromSize, ratio, transform } = useViewport();
  const { playersRef, localX, localY } = useGameMapInteraction();
  const pubnub = usePubNub();
  const selfId = pubnub.getUUID();

  useTimestamp({ every: 500 });

  const size = displayFromSize(0.5, 0.5);

  return (
    <Fragment>
      {Object.keys(playersRef.current).map((playerId) =>
        selfId === playerId ? (
          <LocalPlayer
            key={playerId}
            chatId={playerId}
            localX={localX}
            localY={localY}
          />
        ) : (
          <RemotePlayer
            key={playerId}
            chatId={playerId}
            position={playersRef.current[playerId].position}
            presence={playersRef.current[playerId].presence}
            room={playersRef.current[playerId].room}
          />
        )
      )}

      {__DEV__ ? (
        <Animated.View
          style={[
            {
              transform: [
                ...transform,
                { translateX: multiply(ratio, localX) },
                { translateY: multiply(ratio, localY) },
              ],
              position: 'absolute',
              top: size.width / 2,
              left: size.width / 2,
              borderRadius: size.width / 2,
              backgroundColor: 'rgba(255, 255, 127, 0.8)',
            },
            size,
          ]}
        />
      ) : null}
    </Fragment>
  );
}

function RemotePlayer_({
  chatId,
  position,
  presence,
  room,
}: Pick<PlayerRef, 'position' | 'presence' | 'chatId' | 'room'>) {
  if (position.x === -1 || position.y === -1 || !presence.joined) {
    return null;
  }

  return (
    <RemotePlayerAvatar
      x={position.x}
      y={position.y}
      chatId={chatId}
      room={room.id}
    />
  );
}

function useDisplayCoords(x: number, y: number) {
  const clock = useClock();
  const dt = divide(diff(clock), 1000);
  const [displayX, displayY, actualX, actualY] = useValues(x, y, x, y);

  useCode(() => [set(actualX, x)], [x, actualX]);
  useCode(() => [set(actualY, y)], [y, actualY]);

  useCode(
    () => [
      /*cond(
        neq(room === null ? 1 : 0, clockRunning(clock)),
        startClock(clock),
        cond(neq(room !== null ? 0 : 1, clockRunning(clock)), stopClock(clock))
      )*/ startClock(
        clock
      ),
      cond(
        eq(displayX, actualX),
        [],
        cond(
          greaterThan(displayX, actualX),
          // move left
          set(displayX, max(actualX, add(displayX, multiply(dt, -2)))),
          // move right
          set(displayX, min(actualX, add(displayX, multiply(dt, 2))))
        )
      ),
      cond(
        eq(displayY, actualY),
        [],
        cond(
          greaterThan(displayY, actualY),
          // move up
          set(displayY, max(actualY, add(displayY, multiply(dt, -2)))),
          // move down
          set(displayY, min(actualY, add(displayY, multiply(dt, 2))))
        )
      ),

      // Always diff dt
      dt,
    ],
    [clock, displayX, displayY, actualX, actualY]
  );

  return { displayX, displayY };
}

function LocalPlayer_({
  chatId,
  localX,
  localY,
}: {
  chatId: string;
  localX: Animated.Node<number>;
  localY: Animated.Node<number>;
}) {
  const info = useChatUserInfo({ id: chatId });
  const image = useChatImage(info, 'icon_64');

  return (
    <PlayerAvatar
      displayX={localX}
      displayY={localY}
      name={info?.name.full}
      image={image}
      room={null}
    />
  );
}

function RemotePlayerAvatar({
  chatId,
  x,
  y,
  room,
}: {
  chatId: string;
  x: number;
  y: number;
  room: string | null;
}) {
  const {
    selected: { select },
  } = useGameMapInteraction();

  const info = useChatUserInfo({ id: chatId });
  // const initials = useChatInitials(info)
  const image = useChatImage(info, 'icon_64');
  const { displayX, displayY } = useDisplayCoords(x, y);
  const onSelect = useCallback(() => select(`player/${chatId}`), [
    chatId,
    select,
  ]);

  return (
    <PlayerAvatar
      displayX={displayX}
      displayY={displayY}
      name={info?.name.full}
      image={image}
      room={room}
      onPress={onSelect}
    />
  );
}

function PlayerAvatar_({
  displayX,
  displayY,
  name,
  image,
  room,
  onPress,
}: {
  displayX: Animated.Node<number>;
  displayY: Animated.Node<number>;
  name: string | null | undefined;
  image: string | null | undefined;
  room: string | null;
  onPress?: () => void;
}) {
  const { displayFromSize, transform } = useViewport();
  const initials = useChatInitials({ name: { full: name || '' } });
  const size = displayFromSize(1, 1);
  const ratio = size.width;

  return (
    <Animated.View
      accessibilityLabel={`Representation of ${name || 'a player'}`}
      style={[
        {
          top: 0,
          left: 0,
          width: size.width,
          height: size.height,
          position: 'absolute',
          padding: 2,
          zIndex: 3,
          transform: [
            ...transform,
            { translateX: multiply(displayX, ratio) },
            { translateY: multiply(displayY, ratio) },
          ],
        },
      ]}
    >
      <Card
        style={[
          {
            padding: 0,
            borderRadius: ratio / 2,
            width: '100%',
            height: '100%',
          },
        ]}
        onPress={onPress}
      >
        {image ? (
          <Animated.Image
            source={{
              uri: image,
              width: 64,
              height: 64,
            }}
            style={[
              {
                borderRadius: ratio / 2,
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                opacity: room === null ? 1 : 0.5,
              },
            ]}
          />
        ) : (
          <Animated.View
            style={{
              backgroundColor: '#ddd',
              borderRadius: ratio / 2,
              width: '100%',
              height: '100%',
              overflow: 'hidden',
              opacity: room === null ? 1 : 0.5,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text style={{ color: '#333' }}>{initials}</Text>
          </Animated.View>
        )}
      </Card>
    </Animated.View>
  );
}

const RemotePlayer = React.memo(RemotePlayer_);
const LocalPlayer = React.memo(LocalPlayer_);
const PlayerAvatar = React.memo(PlayerAvatar_);

export const Players = React.memo(Players_);
