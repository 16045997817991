import { TactileGroupUser } from '@introcloud/api-client';
import { useBlockData } from '@introcloud/blocks';
import React, { useCallback, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Avatar, Chip } from 'react-native-paper';

import { ContactMenu } from './ContactMenu';

export function ContactChip({ contact }: { contact: TactileGroupUser }) {
  const [active, setActive] = useState(false);
  const toggleChipDetails = useCallback(() => setActive((prev) => !prev), [
    setActive,
  ]);
  const { getImageUrl } = useBlockData();

  const initials = [contact.name.first, contact.name.last]
    .filter(Boolean)
    .map((name) => name[0])
    .join('');

  const firstName = contact.name.first || contact.name.full.split(' ').shift();
  const illustration = getImageUrl(contact.image.profile || '', 'icon_64');
  const anchor = (
    <Chip
      avatar={
        illustration ? (
          <Avatar.Image
            source={{ uri: illustration, width: 65, height: 64 }}
            size={24}
          />
        ) : (
          <Avatar.Text size={24} label={initials} />
        )
      }
      onPress={toggleChipDetails}
      style={styles.chip}
    >
      {firstName}
    </Chip>
  );

  return (
    <ContactMenu
      active={active}
      onDismiss={toggleChipDetails}
      anchor={anchor}
      id={contact._id}
      name={contact.name.first || contact.name.full.split(' ')[0]}
      phone={
        contact.mobile?.valid
          ? `${contact.mobile.countrycode || ''}${contact.mobile.value}`
          : undefined
      }
    />
  );
}

const styles = StyleSheet.create({
  chip: {
    marginBottom: 4,
    marginRight: 2,
    marginLeft: 2,
  },
});
