import { tabName } from '@introcloud/tabs';
import { useNavigation, useRoute } from '@react-navigation/native';
import React, { useMemo } from 'react';
import { RouteProp } from '../core/Routes';
import { useCompanyTabs } from '../hooks/useCompanyTabs';

import { useTabConfiguration } from '../hooks/useTab';
import { useTabColor } from '../hooks/useTabColor';
import { BlockProvidedPage } from '../page/Page';

export function InformationTab() {
  const {
    destination: { kind, value: page },
  } = useTabConfiguration('information');
  const color = useTabColor('information');

  if (__DEV__) {
    if (kind !== 'info') {
      console.warn('Expected info page, got ' + kind);
    }
  }

  return (
    <BlockProvidedPage
      page={page}
      hideBack
      colorOverride={color}
      screen="InformationTab"
    />
  );
}

const INFO_TAB_CONFIGURATION_IDENTIFIERS = [
  'information',
  'information2',
  'information3',
  'information4',
  'information5',
] as const;

export function InformationSecondaryTab() {
  const { name } = useRoute();

  const { configuration: allTabs } = useCompanyTabs();
  const matchingTab = useMemo(
    () =>
      INFO_TAB_CONFIGURATION_IDENTIFIERS.find(
        (identifier) => tabName(identifier) === name
      ),
    [allTabs, name]
  );

  const {
    destination = { kind: undefined, value: undefined },
  } = useTabConfiguration(matchingTab as 'information');
  const { kind, value: realPage } = destination;
  const color = useTabColor(matchingTab!);

  if (__DEV__) {
    if (kind !== 'info') {
      console.warn('Expected info page, got ' + kind);
    }
  }

  return (
    <BlockProvidedPage
      page={realPage || null}
      hideBack
      colorOverride={color}
      screen="InformationSecondaryTab"
    />
  );
}
