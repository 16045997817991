import { TactileSponsor } from '@introcloud/api-client';
import { EventListItem, Spacer, useBlockData } from '@introcloud/blocks';
import Color from 'color';
import React, { useMemo } from 'react';
import {
  Platform,
  SectionList,
  SectionListData,
  StyleSheet,
  View,
} from 'react-native';
import { List, Surface, Text, useTheme } from 'react-native-paper';
import { localeDateString } from 'react-native-time-helpers';
import { BlockProvision } from '../core/BlockProvision';
import { Header } from '../core/Header';
import { STATIC_SPONSORS_ENABLED } from '../features';
import { PreparedEvent, useEvents } from '../hooks/useEvents';
import { useSponsors } from '../hooks/useSponsors';
import { extractEventData } from '../utils';
import { RemoteSponsor, StaticSponsor } from './Sponsor';

export function EventsScreen({
  asTab,
  title,
}: {
  asTab?: boolean;
  title?: string;
}) {
  return (
    <BlockProvision screen="EventsScreen">
      <View
        style={{
          flex: 1,
          overflow: 'hidden',
          maxHeight: Platform.select({
            web: '100vh',
            default: '100%',
          }),
        }}
      >
        <Header
          title={title || 'Calendar'}
          subTitle={undefined}
          hideBack={asTab}
        />
        <Calendar />
      </View>
    </BlockProvision>
  );
}

const EMPTY: readonly PreparedEvent[] = [];
const EMPTY_SPONSORS: readonly Omit<TactileSponsor, 'pageRef'>[] = [];

function Calendar() {
  // TODO get events directly from BlockProvision?
  const { data: events } = useEvents();
  const { data: sponsors } = useSponsors();
  const sections = useEventSections(
    events || EMPTY,
    sponsors || EMPTY_SPONSORS
  );

  return (
    <SectionList
      nativeID="scroller"
      style={{
        flex: 1,
        maxHeight: '100%',
      }}
      sections={sections}
      keyExtractor={extractKey}
      ListHeaderComponent={() =>
        Platform.OS === 'web' ? <Spacer space={2} /> : null
      }
      initialNumToRender={15}
      renderSectionHeader={renderHeader}
      renderItem={renderItem}
      renderSectionFooter={renderFooter}
      contentContainerStyle={{
        maxWidth: 720,
        alignSelf: 'center',
        width: '100%',
      }}
    />
  );
}

type Item =
  | { type: 'event'; event: PreparedEvent }
  | { type: 'sponsor'; day: string; sponsorId?: string };

function extractKey(item: Item, index: number) {
  switch (item.type) {
    case 'event': {
      return item.event._id + '-' + index;
    }
    case 'sponsor': {
      return item.day;
    }
  }
}

function renderItem({ item }: { item: Item }) {
  switch (item.type) {
    case 'event': {
      return <EventItem event={item.event} key={item.event._id} />;
    }
    case 'sponsor': {
      if (STATIC_SPONSORS_ENABLED) {
        return <StaticSponsor key={item.day} day={item.day} />;
      }

      if (!item.sponsorId) {
        return null;
      }

      return (
        <RemoteSponsor
          key={item.day}
          day={item.day}
          sponsorId={item.sponsorId}
        />
      );
    }
  }
}

function EventItem({ event }: { event: PreparedEvent }) {
  const { getImageUrl } = useBlockData();
  return (
    <Surface style={{ elevation: 1, width: '100%' }}>
      <EventListItem
        source={
          extractEventData(
            event,
            { isLast: true, loading: false },
            { getImageUrl }
          ) || event._id
        }
        isLast
      />
    </Surface>
  );
}

function renderHeader({ section }: { section: SectionListData<Item> }) {
  const date = new Date(`${section.date}T11:00:00`);
  return <SectionHeader date={date} />;
}

function SectionHeader({ date }: { date: Date }) {
  const {
    colors: { accent },
  } = useTheme();

  const color = new Color(accent);

  const isDark = color.isDark();
  const textColor = isDark ? '#fff' : '#121212';

  return (
    <Surface
      style={{ elevation: Platform.OS === 'ios' ? 2 : 1 }}
      theme={{ colors: { surface: accent } }}
    >
      <List.Subheader style={styles.header}>
        <Text style={[styles.headerText, { color: textColor }]}>
          {localeDateString(date)}
        </Text>
      </List.Subheader>
    </Surface>
  );
}

function renderFooter() {
  return <Spacer space={2} />;
}

function useEventSections(
  events: readonly PreparedEvent[],
  sponsors: readonly Omit<TactileSponsor, 'pageRef'>[]
) {
  const showEvents = useMemo(
    () => events.filter((event) => event.hierarchy.showInCalendar) || [],
    [events]
  );

  const showSponsors = useMemo(
    () =>
      sponsors.filter(
        (sponsor) => sponsor.kind === 'event' && sponsor.weight > 0
      ),
    [sponsors]
  );

  const sections = useMemo(() => {
    let lastDate: number | null = null;

    const [first, last] = showEvents.reduce(
      (results, event) => {
        if (event.hierarchy.isMain) {
          return results;
        }

        if (results[0] === undefined) {
          return [event.duration.start.unix, event.duration.end.unix];
        }

        const nextFirst = Math.min(results[0], event.duration.start.unix);
        const nextLast = Math.max(results[1], event.duration.end.unix);
        return [nextFirst, nextLast];
      },
      [undefined, undefined] as [number, number] | [undefined, undefined]
    );

    const sections = showEvents.reduce((days, event) => {
      const date = new Date(event.duration.start.unix);
      date.setHours(12);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);

      if (lastDate !== date.getTime()) {
        days.push({ date: date.toISOString().split('T').shift()!, data: [] });
        lastDate = date.getTime();
      }

      const { data } = days[days.length - 1];
      data.push({ type: 'event', event });

      return days;
    }, [] as { date: string; data: Item[] }[]);

    return sections.map((section) => {
      return {
        ...section,
        data: [
          ...section.data,
          { type: 'sponsor' as const, day: section.date },
        ],
      };
    });
  }, [showEvents]);

  return useMemo(() => {
    if (showSponsors.length === 0) {
      return sections;
    }

    let unpicked = showSponsors.reduce((result, sponsor) => {
      const repeated = new Array(sponsor.weight).fill(sponsor) as typeof result;
      return result.concat(repeated);
    }, [] as typeof showSponsors);

    return sections.map((section) => {
      // Nothing left to pick
      if (unpicked.length === 0) {
        return section;
      }

      // Weighted pick
      const picked = unpicked[Math.floor(Math.random() * unpicked.length)];
      unpicked = unpicked.filter((u) => u._id !== picked._id);

      section.data.splice(section.data.length - 1, 1, {
        type: 'sponsor' as const,
        day: section.date,
        sponsorId: picked._id,
      });
      return section;
    });
  }, [sections, showSponsors]);
}

const styles = StyleSheet.create({
  header: {
    width: '100%',
    justifyContent: 'space-around',
  },
  headerText: {
    fontSize: Platform.OS === 'ios' ? 16 : 18,
  },
});

/*
  const sponsorOptions = sponsorsRef.current || (await reloadSponsors());

      if (!sponsorOptions || sponsorOptions.length === 0) {
        throw new Error('No sponsors to show');
      }

      const kindly = sponsorOptions.filter(
        (sponsor) => sponsor.kind === kind && sponsor.weight !== 0
      );
      const length = kindly.reduce(
        (result, sponsor) => result + sponsor.weight,
        0
      );
      if (length === 0) {
        throw new Error('No sponsors to show');
      }

      const splashPrevious = randomizerRef.current
        ? randomizerRef.current[kind]
        : undefined;

      const previous =
        typeof splashPrevious === 'undefined'
          ? Math.floor(Math.random() * length)
          : splashPrevious;
      const now = (previous + 1) % length;

      setRandomizer((prev) => merge({}, prev || {}, { [kind]: now }));

      const items: TactileSponsor[] = [];
      kindly.forEach((o) => {
        items.push(...Array(o.weight).fill(o));
      });

      const item = items[now];
      if (item) {
        return item;
      }

      throw new Error('Something went wrong grabbing a sponsor');
      */
