import { TactileConversation } from '@introcloud/api-client';
import { useCallback } from 'react';
import { NotReady } from '../core/errors/NotReady';
import { useAbortController } from './useAbortController';
import { useEndpoint, useSafeAuthorization } from './useAuthentication';

export function useChannelOneFetcher(
  fetcher: (
    id: string,
    authorization: string,
    endpoint: string,
    signal?: AbortSignal,
    debug?: boolean
  ) => Promise<TactileConversation>
) {
  const authorization = useSafeAuthorization();
  const endpoint = useEndpoint();
  const abortable = useAbortController();

  return useCallback(
    (id: string) => {
      if (!endpoint || !authorization) {
        throw new NotReady();
      }

      const ac = abortable();

      const cancellable = fetcher(
        id,
        endpoint,
        authorization,
        ac.signal,
        __DEV__
      );

      // This is a non-standard property on a promise, so the error here needs to
      // be ignored. However, react-query will check this non-standard property
      // and use it if it's available.
      //
      // @ts-ignore
      cancellable.cancel = () => {
        ac && ac.abort();
      };

      return cancellable;
    },
    [authorization, endpoint]
  );
}

export function useChannelTwoFetcher(
  fetcher: (
    id: string,
    id2: string,
    authorization: string,
    endpoint: string,
    signal?: AbortSignal,
    debug?: boolean
  ) => Promise<TactileConversation>
) {
  const authorization = useSafeAuthorization();
  const endpoint = useEndpoint();
  const abortable = useAbortController();

  return useCallback(
    async (id: string, id2: string) => {
      if (!endpoint || !authorization) {
        throw new NotReady();
      }
      const ac = abortable();

      const cancellable = fetcher(
        id,
        id2,
        endpoint,
        authorization,
        ac.signal,
        __DEV__
      );

      // This is a non-standard property on a promise, so the error here needs to
      // be ignored. However, react-query will check this non-standard property
      // and use it if it's available.
      //
      // @ts-ignore
      cancellable.cancel = () => {
        ac && ac.abort();
      };

      return cancellable;
    },
    [authorization, endpoint]
  );
}
