import { useNavigation } from '@react-navigation/native';
import React, { Fragment, useCallback, useState } from 'react';
import { Platform, View } from 'react-native';
import { useTimestamp } from 'react-native-use-timestamp';

import { Header } from '../core/Header';
import { GELEGRAAF } from '../features';
import { Edition } from './GelegraafEditionScreen';
import { GelegraafItem } from './useGelegraaf';

const FIRST_EDITION = new Date(GELEGRAAF?.first?.timestamp || '');
const LAST_EDITION = new Date(GELEGRAAF?.last?.timestamp || '');

function clampDate(date: Date): Date {
  if (date < FIRST_EDITION) {
    return FIRST_EDITION;
  }

  if (date > LAST_EDITION) {
    return LAST_EDITION;
  }

  return date;
}

function AutoUpdateGelegraaf() {
  const { navigate } = useNavigation();
  const timestamp = useTimestamp({ every: 5000 });

  const currentDate = new Date(timestamp);
  const clampedDate = clampDate(currentDate);
  const edition = clampedDate.toISOString().split('T')[0];

  const [selectedEdition] = useState(edition);
  const isCurentEdition = selectedEdition === edition;

  const isLive = isCurentEdition
    ? (currentDate === clampedDate && currentDate.getHours() >= 9) ||
      currentDate > clampedDate
    : currentDate > new Date(`${selectedEdition}T07:00Z`);

  const doGotoArticle = useCallback(
    (article: GelegraafItem) =>
      navigate('GelegraafArticle', { id: article.id, day: edition }),
    [navigate, edition]
  );

  const doGotoEdition = useCallback(
    (nextEdition?: string) =>
      navigate('GelegraafEdition', { day: nextEdition }),
    [navigate]
  );

  return (
    <Edition
      key={`${selectedEdition}-${isLive ? 'live' : 'not-live'}`}
      edition={selectedEdition}
      onGotoArticle={doGotoArticle}
      onGotoEdition={doGotoEdition}
      isLive={isLive}
      isCurrent={isCurentEdition}
    />
  );
}

export function GelegraafScreen() {
  return (
    <View
      style={{
        width: '100%',
        flex: 1,
        height: Platform.select({ web: '100vh', default: '100%' }),
      }}
    >
      <Header title="Gelegraaf" />
      <AutoUpdateGelegraaf />
    </View>
  );
}
