import Constants from 'expo-constants';
import { Platform } from 'react-native';

export * from 'expo-use-memory-value';

if (Platform.OS === 'web') {
  require('expo-use-memory-value/storage.web').setLocalForageInstance(
    require('localforage').createInstance({
      name: `${
        Constants.manifest.extra['introcloud-domain'] ||
        Constants.manifest.extra['whitelabel-domain'] ||
        Constants.manifest.slug
      }.2021`,
    })
  );
}
