import {
  TactileCompanyAdvertisements,
  TactileCompanyEvents,
} from '@introcloud/api-client/dist/fetch/types';
import { BlockSettings } from '@introcloud/blocks';
import { DEFAULT_BLOCK_SETTINGS } from '@introcloud/blocks/dist/BlockSettings';
import { useMemo } from 'react';

import { useCompany } from './useCompany';

export function useProvideBlockSettings(): BlockSettings {
  const company = useCompany();

  return useMemo((): BlockSettings => {
    return {
      ...DEFAULT_BLOCK_SETTINGS,
      Advertisement: getAdvertisementSettings(
        company.application.advertisements
      ),
      EventListItem: getEventListItemSettings(company.application.events),
    };
  }, [company]);
}

function getAdvertisementSettings(
  remoteSettings: TactileCompanyAdvertisements
): BlockSettings['Advertisement'] {
  try {
    return {
      ratio: [
        Number(remoteSettings.defaultRatio.x) as 16,
        Number(remoteSettings.defaultRatio.y) as 9,
      ],
    };
  } catch {
    return DEFAULT_BLOCK_SETTINGS['Advertisement'];
  }
}

function getEventListItemSettings(
  remoteSettings: TactileCompanyEvents
): BlockSettings['EventListItem'] {
  try {
    return {
      type: remoteSettings.type,
      lines: remoteSettings.lines,
      imageEnabled: remoteSettings.imageEnabled,
      tagsEnabled: remoteSettings.tagsEnabled,
    };
  } catch {
    return DEFAULT_BLOCK_SETTINGS['EventListItem'];
  }
}
