import {
  ProvideBlockData,
  ProvideBlockNavigation,
  ProvideBlockSettings,
} from '@introcloud/blocks';
import { PageDataProvider, PageData } from '@introcloud/page';
import React from 'react';

import { useProvideBlockData } from '../hooks/useProvideBlockData';
import { useProvideBlockNavigation } from '../hooks/useProvideBlockNavigation';
import { useProvideBlockSettings } from '../hooks/useProvideBlockSettings';
import { useProvidePageData } from '../hooks/useProvidePageData';

export function BlockProvision({
  children,
  page,
}: React.PropsWithChildren<{ screen?: string; page?: string | PageData }>) {
  const blockData = useProvideBlockData();
  const blockSettings = useProvideBlockSettings();

  return (
    <ProvideBlockData provider={blockData}>
      <ProvideBlockSettings settings={blockSettings}>
        {typeof page === 'string' || page === undefined ? (
          <WithPageData page={page}>{children}</WithPageData>
        ) : (
          <ForwardPageData page={page}>{children}</ForwardPageData>
        )}
      </ProvideBlockSettings>
    </ProvideBlockData>
  );
}

function ForwardPageData({
  page,
  children,
}: React.PropsWithChildren<{ page: PageData }>) {
  return (
    <PageDataProvider value={page}>
      <WithBlockNavigation>{children}</WithBlockNavigation>
    </PageDataProvider>
  );
}

export function WithPageData({
  page,
  children,
}: React.PropsWithChildren<{ page?: string }>) {
  const { data, loading, error } = useProvidePageData(page || null);

  return (
    <PageDataProvider value={data}>
      <WithBlockNavigation>{children}</WithBlockNavigation>
    </PageDataProvider>
  );
}

export function WithBlockNavigation({
  children,
}: React.PropsWithChildren<any>) {
  const navigation = useProvideBlockNavigation();

  return (
    <ProvideBlockNavigation navigation={navigation}>
      {children}
    </ProvideBlockNavigation>
  );
}
