import { useBlockNavigation } from '@introcloud/blocks';
import React, { Fragment } from 'react';
import { Linking } from 'react-native';
import { Menu } from 'react-native-paper';

export interface ContactMenuProps {
  active: boolean;
  onDismiss: () => void;
  anchor: React.ReactNode;
  name: string;
  phone?: string;
  id: string;
  hideChat?: boolean;
}

export function ContactMenu({
  name,
  phone,
  anchor,
  active,
  id,
  onDismiss,
  hideChat,
}: ContactMenuProps) {
  const { gotoChat } = useBlockNavigation();
  const isProbablyMobilePhone =
    phone &&
    (/^(00|\+)310?6/.test(phone) || // Is a Dutch mobile phone
      !/^(00|\+)/.test(phone) || // Doesn't have a country code
      /^(00|\+)([012456789]|3[023456789])/.test(phone)); // Is not a Dutch phone

  return (
    <Menu visible={active} anchor={anchor} onDismiss={onDismiss}>
      {phone ? (
        <Fragment>
          <Menu.Item
            title={`Call ${name}`}
            onPress={() => callPhone(phone)}
            icon="phone"
          />
          {isProbablyMobilePhone ? (
            <Fragment>
              <Menu.Item
                title={`Text ${name}`}
                onPress={() => textPhone(phone)}
                icon="message-text"
              />

              <Menu.Item
                title={`Whatsapp ${name}`}
                onPress={() => whatsAppPhone(phone)}
                icon="whatsapp"
              />
            </Fragment>
          ) : null}
        </Fragment>
      ) : (
        <Menu.Item title="No phone number" />
      )}
      <Menu.Item
        title={`Chat with ${name}`}
        onPress={() => {
          onDismiss();
          gotoChat(`:user/${JSON.stringify({ user: id })}`);
        }}
        icon="forum"
      />
    </Menu>
  );
}

export function callPhone(phone: string) {
  const sanitized = phone.replace(/[^+0-9]/g, '');
  return Linking.openURL(`tel:${sanitized}`);
}

export function textPhone(phone: string) {
  const sanitized = phone.replace(/[^+0-9]/g, '');
  return Linking.openURL(`sms:${sanitized}`);
}

export function whatsAppPhone(phone: string) {
  const sanitized = phone.replace(/[^+0-9]/g, '');
  return Linking.openURL(
    `https://wa.me/${encodeURIComponent(sanitized.replace(/^00/, '+'))}`
  );
}
