import { useBlockNavigation } from '@introcloud/blocks';
import Color from 'color';
import Constants from 'expo-constants';
import { makeUrl } from 'expo-linking';
import linkify from 'linkify-it';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Platform, Text } from 'react-native';
import { useTheme } from 'react-native-paper';

const linkifyIt = linkify().add('ftp', null);

type LinkPart = { href: string; value: string };
type Part = string | LinkPart;

const LinkColorContext = createContext({
  color: 'navy',
  backgroundColor: 'white',
});

const DOMAIN_INTROCLOUD = Constants.manifest.extra['introcloud-domain'];
const DOMAIN_WHITELABEL = Constants.manifest.extra['whitelabel-domain'];

const INTERNAL_PREFIXES = [makeUrl('/')].concat(
  [DOMAIN_INTROCLOUD, DOMAIN_WHITELABEL]
    .filter(Boolean)
    .map((domain) => 'https://' + domain)
);

export function LinkColorProvider({
  children,
}: React.PropsWithChildren<unknown>) {
  const {
    colors: { accent, primary },
    dark,
  } = useTheme();

  const value = useMemo(() => {
    const color = new Color(accent);

    if (color.isDark() !== dark) {
      return {
        color: accent,
        backgroundColor: dark ? 'rgba(0, 0, 0, .1)' : '#fff',
      };
    }

    const altColor = new Color(primary);

    if (altColor.isDark() !== dark) {
      return {
        color: primary,
        backgroundColor: dark ? 'rgba(0, 0, 0, .1)' : '#fff',
      };
    }

    return {
      color: accent,
      backgroundColor: dark ? '#fff' : 'rgba(0, 0, 0, .1)',
    };
  }, [accent, dark]);

  return (
    <LinkColorContext.Provider value={value}>
      {children}
    </LinkColorContext.Provider>
  );
}

function AutoLinkText_({
  children,
  textAlign,
  alignSelf,
}: {
  children: string;
  textAlign?: 'left' | 'right';
  alignSelf?: 'flex-start' | 'flex-end';
}): JSX.Element {
  const [linkified, setLinkified] = useState<Part[]>(() => [children]);
  const {
    colors: { text: textColor },
  } = useTheme();

  useEffect(() => {
    if (!linkifyIt.pretest(children)) {
      return;
    }

    const matches = linkifyIt.match(children);
    if (!matches || matches.length === 0) {
      return;
    }

    const next: Part[] = [];
    let pointer = 0;

    matches.forEach(({ index, lastIndex, text, url }) => {
      const preLink = children.substring(pointer, index);
      preLink && next.push(preLink);

      next.push({
        value: text,
        href: url,
      });

      pointer = lastIndex;
    });

    const postLink = children.substring(pointer);
    postLink && next.push(postLink);

    setLinkified(next);
  }, [children]);

  return (
    <Text style={{ textAlign, alignSelf, color: textColor }} selectable>
      {linkified.map((linkified, index) =>
        typeof linkified === 'string' ? (
          <Text key={index} selectable style={{ color: textColor }}>
            {linkified}
          </Text>
        ) : (
          <Link key={index} part={linkified} />
        )
      )}
    </Text>
  );
}

export const AutoLinkText = React.memo(AutoLinkText_);

function Link({ part: { value, href } }: { part: LinkPart }) {
  const { color, backgroundColor } = useContext(LinkColorContext);

  const internalPath = useMemo(() => {
    const internalPrefix = INTERNAL_PREFIXES.find((prefix) => {
      return href.indexOf(prefix) === 0;
    });

    return internalPrefix ? href.substring(internalPrefix.length) : null;
  }, [href]);

  const { gotoExternal, gotoInternal } = useBlockNavigation();

  if (Platform.OS === 'web') {
    return (
      <a
        href={href}
        style={{
          color,
          borderRadius: 4,
          backgroundColor,
          flexWrap: 'wrap',
          flexShrink: 1,
          paddingLeft: 4,
          paddingRight: 4,
        }}
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();

          if (internalPath) {
            return gotoInternal(internalPath);
          }

          gotoExternal(href);
        }}
      >
        {value}
      </a>
    );
  }

  const handlePress = () => {
    if (internalPath) {
      return gotoInternal(internalPath);
    }

    gotoExternal(href);
  };

  return (
    <Text
      onPress={handlePress}
      onLongPress={handlePress}
      selectable
      style={{
        color,
        borderRadius: 4,
        backgroundColor,
        flexWrap: 'wrap',
        flexShrink: 1,
        fontSize: 12,
      }}
    >
      {value}
    </Text>
  );
}
