import {
  fetchApplicationEventAttendance,
  TactileAttendanceResponse,
} from '@introcloud/api-client';
import { useIsFocused } from '@react-navigation/core';
import { FetchMediaError } from 'fetch-media';
import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { NothingToFetch } from '../core/errors/NothingToFetch';
import { NotReady } from '../core/errors/NotReady';
import { useAbortController } from './useAbortController';
import { useEndpoint, useSafeAuthorization } from './useAuthentication';

export function useSwipeInterviewGuest(
  event: string,
  { enabled = true }: { enabled?: boolean } = {}
) {
  const isFocused = useIsFocused();
  const authorization = useSafeAuthorization();
  const endpoint = useEndpoint();

  const abortable = useAbortController();
  const fetcher = useCallback(() => {
    if (!authorization || !endpoint) {
      throw new NotReady();
    }

    if (!event) {
      throw new NothingToFetch();
    }

    const ac = abortable();

    const cancellable = fetchApplicationEventAttendance(
      event,
      endpoint,
      authorization,
      ac.signal,
      __DEV__
    );

    // This is a non-standard property on a promise, so the error here needs to
    // be ignored. However, react-query will check this non-standard property
    // and use it if it's available.
    //
    // @ts-ignore
    cancellable.cancel = () => {
      ac && ac.abort();
    };

    return cancellable;
  }, [authorization, endpoint, event, abortable]);

  const { data: eventGuest, error, ...others } = useQuery<
    TactileAttendanceResponse,
    FetchMediaError | NotReady
  >(['application', 'swipe', 'guest', event], fetcher, {
    refetchOnWindowFocus: false,
    enabled: enabled && !!(event && authorization && endpoint) && isFocused,
  });

  return {
    data: eventGuest,
    error,
    reload: others.refetch,
    loading: others.isLoading,
    refreshing: others.isFetching && !others.isLoading,
    ...others,
  };
}
