import {
  appleDirectionsUrl,
  appleMapsUrl,
  BlockNavigation,
  googleDirectionsIosUrl,
  googleDirectionsUrl,
  googleMapsIosUrl,
  googleMapsUrl,
  useBlockData,
} from '@introcloud/blocks';
import { DEFAULT_BLOCK_NAVIGATION } from '@introcloud/blocks/dist/BlockNavigation';
import { usePageData } from '@introcloud/page';
import { TabIdentifier, TAB_TO_NAME_MAPPING } from '@introcloud/tabs';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useMemo } from 'react';
import { Linking, Platform } from 'react-native';
import { useTheme } from 'react-native-paper';
import { Analytics } from '../analytics';
import { RouteParamList } from '../core/Routes';
import { useVideoPlayer } from '../live/PlayerContext';
import { openExternalUrl } from '../open';
import { merge } from '../utils';

export function useProvideBlockNavigation(): BlockNavigation {
  const {
    colors: { primary },
  } = useTheme();
  const { page } = usePageData();
  const { getTimeslotsContent } = useBlockData();
  const navigation = useNavigation<StackNavigationProp<RouteParamList>>();
  const { setVideo } = useVideoPlayer();
  const linkTo = useLinkTo();

  return useMemo((): BlockNavigation => {
    return {
      ...DEFAULT_BLOCK_NAVIGATION,
      gotoEvent: (id: string) =>
        id && id.trim() && navigation.push('Event', { id }),
      gotoLocation: (id: string) =>
        id && id.trim() && navigation.push('Location', { id }),
      gotoInfo: (id: string) =>
        id &&
        id.trim() &&
        navigation.push('Info', { screen: 'Page', params: { id } }),
      gotoExternal: (url: string) => {
        Analytics.logEvent('gotoExternal', {
          url,
          source: { page: { id: page?._id } },
        });

        openExternalUrl(url, primary).catch(() => {
          /* swallow error */
        });
      },
      gotoInternal(path: string) {
        path = path[0] === '/' ? path.slice(1) : path;

        linkTo(
          '/' +
            path.replace(
              ':event',
              page?.eventRef[0]?.eventId || 'no-linked-event'
            )
        );
      },
      gotoChat: (id: string, replace?: boolean) => {
        id = id.includes('%3A') ? decodeURIComponent(id) : id;

        if (id.includes(':')) {
          const [scopes, context] = id.split('/');
          navigation.push('ResolveChat', {
            scopes: scopes.replace(/:/g, '').split(/[-.]/g),
            context: merge(JSON.parse(context || '{}'), { page: page?._id }),
          });
          return;
        }

        if (replace) {
          navigation.pop();
          navigation.push('Chat', {
            id,
          });
        } else {
          navigation.push('Chat', {
            id,
          });
        }
      },
      gotoTab: (id: string) =>
        navigation.navigate(TAB_TO_NAME_MAPPING[id as TabIdentifier] as any),
      gotoDirections: (
        destination:
          | string
          | [number, number]
          | { latitude: number; longitude: number },
        mode: 'none' | 'bicycling' | 'transit' | 'walking'
      ) => {
        if (mode === 'none') {
          return gotoMaps(destination, primary);
        } else {
          return gotoDirections(destination, mode, primary);
        }
      },
      gotoLive(pageId: string) {
        Analytics.logEvent('gotoLive', {
          pageId,
        });

        setVideo({ pageId });
      },
      gotoTimeslot(timeslotId: string) {
        if (lastActiveTimeslotNavigation.current === timeslotId) {
          return;
        }
        lastActiveTimeslotNavigation.current = timeslotId;
        getTimeslotsContent(timeslotId)
          .then((result) => {
            if (lastActiveTimeslotNavigation.current === timeslotId) {
              const id = result.pageRef.pageId!;
              navigation.push('Info', { screen: 'Page', params: { id } });

              setTimeout(
                () => (lastActiveTimeslotNavigation.current = null),
                1
              );
            }
          })
          .catch(() => {});
      },
    };
  }, [navigation, page?._id]);
}

let lastActiveTimeslotNavigation: { current: string | null } = {
  current: null,
};

function gotoMaps(
  destination:
    | string
    | [number, number]
    | { latitude: number; longitude: number },
  primary: string
): Promise<unknown> {
  const coords_ = (typeof destination !== 'string' && destination) || undefined;
  const address = (typeof destination === 'string' && destination) || undefined;
  const coords = Array.isArray(coords_)
    ? { latitude: coords_[0], longitude: coords_[1] }
    : coords_;

  switch (Platform.OS) {
    case 'ios': {
      return Linking.openURL(googleMapsIosUrl(coords, address))
        .catch(() => {
          const appleMaps = appleMapsUrl(coords, address);
          return Linking.canOpenURL(appleMaps)
            ? Linking.openURL(appleMaps)
            : Promise.reject(new Error('Apple Maps not installed'));
        })
        .catch(() => {
          return openExternalUrl(googleMapsUrl(coords, address), primary);
        });
    }
    case 'android': {
      return Linking.canOpenURL('geo:0.0, 0.0')
        ? Linking.openURL(googleMapsUrl(coords, address))
        : Promise.reject(new Error('Google Maps not installed')).catch(() => {
            return openExternalUrl(googleMapsUrl(coords, address), primary);
          });
    }
    default: {
      return openExternalUrl(googleMapsUrl(coords, address), primary);
    }
  }
}

function gotoDirections(
  destination:
    | string
    | [number, number]
    | { latitude: number; longitude: number },
  mode: 'none' | 'bicycling' | 'transit' | 'walking',
  primary: string
) {
  const coords_ = (typeof destination !== 'string' && destination) || undefined;
  const address = (typeof destination === 'string' && destination) || undefined;
  const coords = Array.isArray(coords_)
    ? { latitude: coords_[0], longitude: coords_[1] }
    : coords_;

  switch (Platform.OS) {
    case 'ios': {
      return Linking.openURL(googleDirectionsIosUrl(coords, address, mode))
        .catch(() => {
          const appleMaps = appleDirectionsUrl(
            coords,
            address,
            mode === 'transit' ? 'r' : 'w'
          );
          return Linking.canOpenURL(appleMaps)
            ? Linking.openURL(appleMaps)
            : Promise.reject(new Error('Apple Maps not installed'));
        })
        .catch(() => {
          return openExternalUrl(
            googleDirectionsUrl(coords, address, mode),
            primary
          );
        });
    }
    case 'android': {
      return Linking.canOpenURL('geo:0.0, 0.0')
        ? Linking.openURL(googleDirectionsUrl(coords, address, mode))
        : Promise.reject(new Error('Google Maps not installed')).catch(() => {
            return openExternalUrl(
              googleDirectionsUrl(coords, address, mode),
              primary
            );
          });
    }
    default: {
      return openExternalUrl(
        googleDirectionsUrl(coords, address, mode),
        primary
      );
    }
  }
}
