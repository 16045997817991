import React from 'react';

import { useTabTitle } from '../hooks/useTabTitle';
import { EventsScreen } from './EventsScreen';

export function EventsTab() {
  const title = useTabTitle('events', 'Calendar');

  return <EventsScreen asTab title={title} />;
}
