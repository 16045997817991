import { MessageEvent } from 'pubnub';
import { useEffect, useMemo, useState } from 'react';

import {
  StoredMemoryValue,
  useMemoryValue,
  useMutableMemoryValue,
} from '../storage';
import { runOnLogout } from './useAuthentication';

export type ChatMembership = {
  id: string;
  eTag: string;
  updated: string;
  custom: {
    type: 'page' | 'group' | 'page-group' | 'page-1-on-1' | '1-on-1' | 'self';
    refs: { model: 'page' | 'user' | 'group'; id: string }[];
  };
};

export type TypedMessageEvent = Omit<MessageEvent, 'message'> & {
  message: AnyMessage;
};

export type AnyMessage = ChatMessage | SelfMessage | GameMapMessage;

export type SelfMessage =
  | SubscribeMessage
  | KickMessage
  | BlockMessage
  | InviteMessage;

export type GameMapMessage = PositionMessage | RoomMessage;

export type SubscribeMessage = {
  t: 'subscribe';

  c: string;

  // starter user id
  s?: string;
};

export type KickMessage = {
  t: 'kick';

  c: string | undefined;
};

export type BlockMessage = {
  t: 'block';

  // Chat channel
  c: string;
};

export type InviteMessage = {
  t: 'invite';

  // Chat channel
  c: string;
};

export type ChatMessage = {
  // type: chat
  t: 'chat' | 'c';

  // content
  c: string;

  // from user (non-authoritative)
  f: string;

  // unique time token
  u?: string;
};

export type PositionMessage = {
  t: 'position';

  // map x
  x: number;

  // map y
  y: number;

  // from user (non-authoritative)
  f: string;
};

export type RoomMessage = {
  t: 'room';

  // room
  c: string | null;

  // from user (non-authoritative)
  f: string;
};

export const CHATS = new StoredMemoryValue<ChatMembership[]>(
  'application.chats.channels.v1'
);

export const CHATS_ORDER = new StoredMemoryValue<Record<string, string>>(
  'application.chats.channels.order.v1'
);

runOnLogout(() => {
  CHATS.emit(null);
  CHATS_ORDER.emit(null);
});

export function useStoredChats() {
  const chats = useMemoryValue(CHATS);
  const [order, setOrder] = useState(() => CHATS_ORDER.current);

  useEffect(() => {
    return CHATS_ORDER.subscribe((next) => setOrder({ ...next }));
  }, []);

  if (Object.keys(order || {}).length === 0) {
    return (chats?.slice() || []).sort((a, b) =>
      b.updated.localeCompare(a.updated)
    );
  }

  return (
    chats
      ?.map((chat) => ({
        ...chat,
        channelUpdated: chat.updated,
        messageUpdated: order![chat.id],
        chatId: chat.id,

        updated: order![chat.id] || chat.updated,
      }))
      ?.sort((a, b) => b.updated.localeCompare(a.updated)) || []
  );
}

export function useMutableStoredChats() {
  return useMutableMemoryValue(CHATS);
}
