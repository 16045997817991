import { useWindowWidth } from '@introcloud/blocks';
import { useRoute } from '@react-navigation/native';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import {
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  TouchableHighlight,
  View,
} from 'react-native';
import { Button, Card, Dialog, Title, useTheme } from 'react-native-paper';
import HTML from 'react-native-render-html';

import { ErrorBoundary } from '../core/ErrorBoundary';
import { Header } from '../core/Header';
import { RouteProp } from '../core/Routes';
import { openExternalUrl } from '../open';
import { HTML_DEFAULT_PROPS } from './HTMLProps';
import { GelegraafItem, useGelegraaf } from './useGelegraaf';
import { useSafe } from './useSafeHtmlContent';

const styles = StyleSheet.create({
  header: { paddingHorizontal: 16, paddingTop: 8 },
});

function stripSizes(input: string): string {
  return input.replace(/width=".*?"/g, '').replace(/height=".*"/g, '');
}

function Article({ article }: { article: GelegraafItem }) {
  const { title, featured_media, content } = article;
  const image =
    (featured_media &&
      featured_media.media_details &&
      featured_media.media_details.sizes &&
      featured_media.media_details.sizes['blog-full'] &&
      featured_media.media_details.sizes['blog-full'].source_url) ||
    null;

  const safeTitle = useSafe((title || { rendered: '' }).rendered);
  const safeDescription = useSafe((content || { rendered: '' }).rendered);
  const width = Math.min(720, useWindowWidth());
  const height = (width / 16) * 10;

  return (
    <ScrollView
      nativeID="scroller"
      style={{
        flex: 1,
        maxHeight: '100%',
      }}
      contentContainerStyle={{
        maxWidth: 720,
        alignSelf: 'center',
        paddingBottom: 56,
        width: '100%',
      }}
    >
      <Card
        style={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          width: '100%',
          paddingBottom: 8,
        }}
      >
        {image && (
          <Image
            source={{ uri: image, width: 1140, height: 500 }}
            style={{ width, height }}
            resizeMode="cover"
          />
        )}
        <Title style={styles.header}>{safeTitle}</Title>
        <ErrorBoundary>
          <BlockShallowUpdates compare={content}>
            {(content && (
              <HTML
                html={stripSizes(content.rendered)}
                {...(HTML_DEFAULT_PROPS as any)}
              />
            )) ||
              null}
          </BlockShallowUpdates>
        </ErrorBoundary>
      </Card>
    </ScrollView>
  );
}

class BlockShallowUpdates extends React.Component<{ compare: any }> {
  shouldComponentUpdate(nextProps: { compare: string }) {
    return !isEqual(nextProps.compare, this.props.compare);
  }

  render() {
    return this.props.children || null;
  }
}

const ITEM_REGEX = /<a[^>]+?href=['"]([^'"]+?)['"][^>]*?>[^<]*?<img[^>]+?src=['"]([^'"]+?)['"][^>]+?>[^<]*?<\/a>/g;

function Gallery({ article }: { article: GelegraafItem }) {
  const windowWidth = useWindowWidth();
  const fullWidth = Math.floor(Math.min(windowWidth, 720 || Infinity)) - 36;
  const fullHeight = Math.round((fullWidth / 3) * 2);

  const { title, content, date_gmt, id } = article;
  const {
    colors: { primary },
  } = useTheme();

  const safeImages = useSafe(
    (content || { rendered: '' }).rendered,
    '<img> <a>'
  );

  const [full, setFull] = useState<string | undefined>(undefined);
  const [isFullVisible, setFullVisible] = useState(false);

  const doDismiss = useCallback(() => setFullVisible(false), [setFullVisible]);
  const doSave = useCallback(() => {
    if (!full) {
      return;
    }

    openExternalUrl(full, primary);
  }, [full]);

  const showFull = useCallback(
    (full: string) => {
      setFull(full);
      setFullVisible(true);
    },
    [setFullVisible, setFull]
  );

  const images = useMemo(() => {
    const result = [];

    let match = undefined;
    while ((match = ITEM_REGEX.exec(safeImages)) != null) {
      result.push({ full: match[1], src: match[2] });
    }

    return result;
  }, [safeImages]);

  return (
    <Fragment>
      <ScrollView
        nativeID="scroller"
        style={{
          flex: 1,
          maxHeight: '100%',
        }}
        contentContainerStyle={{
          maxWidth: 1000,
          alignSelf: 'center',
          paddingBottom: 56,
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <ImageGrid images={images} onShowFull={showFull} />
      </ScrollView>

      <Dialog
        visible={isFullVisible}
        onDismiss={doDismiss}
        style={{
          maxWidth: 720,
          alignSelf: 'center',
          minWidth: 300,
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <Dialog.Content
          style={{ paddingTop: 0, paddingBottom: 0, paddingHorizontal: 0 }}
        >
          {(full && (
            <Image
              source={{ uri: full }}
              style={{ width: '100%', height: fullHeight }}
              width={fullWidth}
              height={fullHeight}
            />
          )) ||
            null}
        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={doDismiss}>Close</Button>
          <Button mode="contained" onPress={doSave} style={{ marginLeft: 4 }}>
            Save
          </Button>
        </Dialog.Actions>
      </Dialog>
    </Fragment>
  );
}

function ImageGrid({
  images,
  onShowFull,
}: {
  images: readonly { full: string; src: string }[];
  onShowFull(full: string): void;
}) {
  const windowWidth = useWindowWidth();

  const renderImage = useCallback(
    (image: { src: string; full: string }) => {
      return (
        <TouchableHighlight
          key={image.src}
          activeOpacity={0.54}
          onPress={() => onShowFull(image.full)}
          style={{
            flex: 1,
            maxWidth: 300,
            flexBasis: Math.min(280, windowWidth),
          }}
        >
          <Image
            source={{
              uri: image.src,
              width: 300,
              height: 200,
            }}
            style={{ maxWidth: '100%', maxHeight: '100%' }}
            resizeMode="cover"
          />
        </TouchableHighlight>
      );
    },
    [onShowFull, windowWidth]
  );

  return (
    <View
      style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
      }}
    >
      {images.map(renderImage)}
    </View>
  );
}

export function GelegraafArticleScreen() {
  const {
    params: { id, day },
  } = useRoute<RouteProp<'GelegraafArticle'>>();

  const { data, error } = useGelegraaf(day);

  const article = useMemo(
    () => data?.find((d) => String(d.id) === String(id)),
    [data, id]
  );

  if (!article) {
    return (
      <View
        style={{
          width: '100%',
          flex: 1,
          height: Platform.select({ web: '100vh', default: '100%' }),
        }}
      >
        <Header
          title=""
          backFallback={{ screen: 'Gelegraaf', params: undefined }}
        />
      </View>
    );
  }

  return <GelegraafArticle article={article} />;
}

function GelegraafArticle({ article }: { article: GelegraafItem }) {
  const title = useSafe((article.title || { rendered: '' }).rendered);
  const isGallery = article.listing_type === 'gallery';

  return (
    <View
      style={{
        width: '100%',
        flex: 1,
        height: Platform.select({ web: '100vh', default: '100%' }),
      }}
    >
      <Header
        title={title}
        backFallback={{ screen: 'Gelegraaf', params: undefined }}
      />
      {isGallery ? (
        <Gallery article={article} />
      ) : (
        <Article article={article} />
      )}
    </View>
  );
}
